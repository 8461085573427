import styled from '@emotion/styled'

export const Background = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--color-hover-black-dark);
  z-index: 1000;
  top: 0;
  left: 0;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;

  p {
    color: var(--color-light);
    margin-top: 20px;
    text-align: center;
    padding: 0 15px;
    max-width: 360px;
  }

  &.default {
    display: block;
    position: relative;
    top: auto;
    left: auto;
  }
`

export const Logo  = styled.div`
  width: 50px;
  height: 50px;
  margin-left: calc(50% - 25px);
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);

  @keyframes logo-1 {
    0% {
      width: 0;
      height: 0;
      margin-left: 50%;
      margin-top: 50%;
      opacity: 0;
    }
    25%, 50%, 75% {
      margin-top: 20%;
      height: 80%;
      width: 80%;
      margin-left: 0;
      opacity: 1;
    }
    100% {
      width: 0;
      height: 0;
      margin-left: 50%;
      margin-top: 50%;
      opacity: 0;
    }
  }

  @keyframes logo-2 {
    0% {
      width: 100%;
      height: 100%;
      margin-left: 0;
      margin-top: 0;
      opacity: 1;
    }
    25% {
      width: 0;
      height: 0;
      margin-left: 50%;
      margin-top: 50%;
      opacity: 0;
    }
    50%, 75%, 100% {
      width: 100%;
      height: 100%;
      margin-left: 0;
      margin-top: 0;
      opacity: 1;
    }
  }

  @keyframes logo-3 {
    0%, 25% {
      margin-top: 13%;
      height: 80%;
      width: 80%;
      margin-left: 0;
      opacity: 1;
    }
    50% {
      width: 0;
      height: 0;
      margin-left: 50%;
      margin-top: 50%;
      opacity: 0;
    }
    75%, 100% {
      margin-top: 13%;
      height: 80%;
      width: 80%;
      margin-left: 0;
      opacity: 1;
    }
  }

  @keyframes logo-4 {
    0%, 25%, 50% {
      width: 60%;
      margin-left: 20%;
      height: 60%;
      margin-top: 13%;
      opacity: 1;
    }
    75% {
      width: 0;
      height: 0;
      margin-left: 50%;
      margin-top: 50%;
      opacity: 0;
    }
    100% {
      width: 60%;
      margin-left: 20%;
      height: 60%;
      margin-top: 13%;
      opacity: 1;
    }
  }

  span {
    width: 30px;
    height: 30px;
    display: block;

    &:nth-of-type(1) {
      background-color: #96989a;
      animation: logo-1 infinite 1.4s linear;
    }

    &:nth-of-type(2) {
      background-color: #a8cf45;
      animation: logo-2 infinite 1.4s linear;
    }

    &:nth-of-type(3) {
      background-color: #a8cf45;
      animation: logo-4 infinite 1.4s linear;
    }

    &:nth-of-type(4) {
      background-color: #bdbfc1;
      animation: logo-3 infinite 1.4s linear;
    }
  }

  &.small {
    width: 30px;
    height: 30px;
    margin-left: calc(50% - 8px);
  }
`





