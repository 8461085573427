import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClientsContainer, BoxClient, StyledButtonModal } from './styles'
import { formatterCPForCNPJ } from '@/utils/formatter'
import FormEdit from './components/FormEdit'
import Modal from '@/views/fragments/Modal'
import { putMatrizData } from '@/constants/requests'

const ParentData = (props) => {
  const {
    handleEditSubsidiary,
    subsidiary,
    submitFormSuccess
  } = props

  const [showModal, setShowModal] = useState(false)

  const onEditSubsidiary = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleSubmit = async () => {
    const data = await putMatrizData(subsidiary)
    if(data.status.active) {
      setShowModal(false)
      submitFormSuccess()
    }
  }

  const renderEditable = () => (
    <Modal
      closeModal={handleCloseModal}
      size="md"
    >
      <FormEdit
        subsidiary={subsidiary}
        goToParent={onEditSubsidiary}
        onChange={handleEditSubsidiary}
        isEdit
      />
      <StyledButtonModal className='button' onClick={handleSubmit}>Enviar</StyledButtonModal>
    </Modal>
  )

  const renderList = () => (
    <>
      {showModal && renderEditable()}
      <BoxClient
        key={subsidiary.id_matriz}
        className="active"
      >
        <div>
          <div>
            <span>CNPJ</span>
            <p>{formatterCPForCNPJ(subsidiary.doc_number)}</p>
          </div>
          <div>
            <span>Incrição Estadual</span>
            <p>{subsidiary.inscricao_estadual || '-'}</p>
          </div>
          <div>
            <span>Nome Fantasia</span>
            <p>{subsidiary.nome_fantasia || '-'}</p>
          </div>
          <div>
            <span>Razão Social</span>
            <p>{subsidiary.razao_social || '-'}</p>
          </div>
          <div>
            <span>Sigla</span>
            <p>{subsidiary.sigla || '-'}</p>
          </div>
          <div>
            <span>Email</span>
            <p>{subsidiary.email || '-'}</p>
          </div>
          <div>
            <span>Telefone</span>
            <p>{subsidiary.telefone || '-'}</p>
          </div>
          <div className='address'>
            <h4>Endereço</h4>
          </div>
          <div>
            <span>CEP</span>
            <p>{subsidiary.endereco.cep || '-'}</p>
          </div>
          <div>
            <span>Logradouro</span>
            <p>{subsidiary.endereco.logradouro || '-'}</p>
          </div>
          <div>
            <span>numero</span>
            <p>{subsidiary.endereco.numero || '-'}</p>
          </div>
          <div>
            <span>bairro</span>
            <p>{subsidiary.endereco.bairro || '-'}</p>
          </div>
          <div>
            <span>cidade</span>
            <p>{subsidiary.endereco.cidade || '-'}</p>
          </div>
          <div>
            <span>estado</span>
            <p>{subsidiary.endereco.uf || '-'}</p>
          </div>
          <div>
            <span>complemento</span>
            <p>{subsidiary.endereco.complemento || '-'}</p>
          </div>
        </div>
        <div className='buttons'>
          <button
            className='button editar'
            onClick={onEditSubsidiary}
          >
            <i className="fas fa-pencil-alt" />
          </button>
        </div>
      </BoxClient>
    </>
  )

  return (
    <ClientsContainer>
      {subsidiary && renderList()}
    </ClientsContainer>
  )
}

ParentData.propTypes = {
  handleEditSubsidiary: PropTypes.func.isRequired,
  submitFormSuccess: PropTypes.func.isRequired,
  subsidiary: PropTypes.any
}

ParentData.defaultProps = {
  subsidiary: null
}

export default ParentData
