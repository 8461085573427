import styled from '@emotion/styled'

export const StyledContentGeo = styled.section`
  display: grid;
`

export const StyledBodyContainer = styled.section`
  width: 100%;
  padding-top: 5px;

  button {
    &.formButton {
      float: right;
    }
  }
`

export const ListElement = styled.div`
  display: block;
  border: 1px solid var(--color-primary);
  margin: 15px 30px 10px;
  width: calc(100% - 60px);
  border-bottom: none;

  &.full-width {
    margin: 0;
    width: 100%;
  }

  .freight-cursor {
    cursor: pointer;
  }

  .eventos {
    display: flex;
  }

  div {
    &.row {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px solid var(--color-primary);

      & > div {
        padding: 10px;
        width: 100%;

        &.inset {
          @media (min-width: 680px) {
            display: grid;
            grid-template-columns: repeat(2, calc(50% - 5px));
          }

          @media (min-width: 1024px) {
            grid-template-columns: repeat(2, 25%) repeat(3, 16.666667%);
          }
        }

        & > div {
          margin-bottom: 10px;
          padding: 0 4px;

          &:last-of-type, &.filtrar {
            margin-bottom: 0;
          }
        }

        &:first-of-type {
          background-color: var(--color-hover-black-light);
          height: 50px;
          text-align: center;
          width: 100%;
          padding-top: 10px;
        }

        &:first-of-type, &:last-of-type, &.filtrar {
          cursor: pointer;

          &:hover {
            background-color: var(--color-hover-black-dark);

            & > span {
              color: var(--color-third);
            }

            & > p {
              color: var(--color-third-light);
            }

            & > div {
              background-color: var(--color-third);
              color: var(--color-hover-black-dark);
            }
          }
        }

        &:last-of-type, &.filtrar {
          background-color: var(--color-hover-black-light);
          height: 120px;
          text-align: center;
          width: 100%;
          padding-top: 10px;
          position: relative;

          div {
            background-color: var(--color-bg-dark);
            padding: 4px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: var(--color-third-light);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }

        &:first-of-type {
          background-color: var(--color-hover-black-light);
          height: 71px;
          text-align: center;
          width: 100%;
          padding-top: 10px;
          position: relative;
          div {
            background-color: var(--color-bg-dark);
            padding: 4px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            color: var(--color-third-light);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }

        span {
          font-weight: 700;
          color: var(--color-gray-dark);
          font-size: 11px;
          display: block;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        &.name {
          width: 180px;
        }

        button {
          border: 1px solid var(--color-primary);
          background-color: var(--color-primary);
          border-radius: 3px;
          color: var(--color-light);
          font-size: 16px;
          padding: 5px 8px 2px;

          i {
            margin: 0 3px;
          }

          &:hover {
            background-color: var(--color-light);
            color: var(--color-primary);
          }
        }

        &.img-handle {
          cursor: pointer;
          height: auto;
        }
      }

      &:nth-of-type(2n + 1) {
        background-color: var(--color-third-light);
      }

      @media (min-width: 1280px) {
        flex-wrap: nowrap;

        & > div {
          &:first-of-type, &:last-of-type, &.filtrar {
            width: 80px;
            height: 50px;
            padding: 3px;
          }

          p {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @supports (-webkit-line-clamp: 2) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: initial;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }

          &.inset {
            padding: 0;
            width: calc(100% - 180px);

            .event-msg {
              p {
                white-space: initial;
              }
            }
          }

          &.img-handle {
            padding: 3px;

            & > div {
              height: auto;

              .img-list {
                max-height: 40px;
              }
            }
          }
        }
      }

      &.freight {
        & > div {
          @media (min-width: 1280px) {
            &:first-of-type, &:last-of-type, &.filtrar {
              height: 135px;
              justify-content: center;
              padding-top: 35px;
            }

            &:last-of-type, &.filtrar {
              padding-top: 20px;
              width: 120px
            }
          }

          i {
            cursor: pointer;

            &:hover {
              color: var(--color-secondary);
            }
          }
        }

        .inset {
          align-items: center;
          gap: 10px;
          grid-template-columns: repeat(4, calc(24.5% - 7.5px)) 2%;

          & > div {
            padding: 10px 0;
            margin: 0;
            width: 100%;

            & > div {
              &:first-of-type {
                margin-bottom: 10px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    ul {
      li {
        padding: 5px;
        display: grid;
        grid-gap: 5px;
        align-items: center;
        grid-template-columns: 50px 170px repeat(2, calc(50% - 313px)) 130px 175px 70px;

        & > div {
          font-size: 14px;

          &:first-of-type {
            font-weight: 600;
          }
        }

        &:nth-of-type(2n + 1) {
          background-color: var(--color-third-light);
        }

        &.table-header {
          div {
            span {
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  .macro-list {
    background-color: var(--color-third);
    padding: 5px;

    h2 {
      text-align: center;
      padding: 10px;
      color: var(--color-light);
      text-shadow: 2px 2px var(--color-primary);
    }

    & > div {
      background-color: var(--color-light);
    }
  }

  @media (min-width: 1380px) {
    ul {
      li {
        grid-template-columns: 100px 290px repeat(2, calc(50% - 315px)) 220px;
      }
    }
  }
  
  .lg {
    background-color: red;
  }

  @media (min-width: 1380px) {
    ul {
      .lista {
        .titulo-macro {
          display: grid;
          grid-template-columns: 125px 170px 290px 150px 150px 150px 100px !important;
        }

        &.table-header {
          div {
            span {
              font-weight: 800;
            }
          }
        }
      }
    }

    ul {
      .lista {
        display: grid;
        grid-template-columns: 125px 170px 290px 150px 150px 150px 100px !important;
      }
    }
  }   

  .imagem-modal {
    height: 370px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid-frete {
    display: grid;
    gap: 8px;
  }

  .font-phone {
    color: var(--color-gray-dark) !important;
    font-size: 18px !important;
  }
`;

export const StyledImage = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .img-list {
    max-width: 80%;
    height: 100%;
  }

  .img-modal {
    max-width: 80%;
    height: 100%;
  }

  @media (max-width: 700px) {
    height: 80px;
  }
`

export const StyledImageModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .img-list {
    max-width: 80%;
    height: 100%;
  }

  .img-modal {
    max-width: 80%;
    height: 100%;
  }

  @media (max-width: 700px) {
    height: 100%;
  }
`

export const StyledModal = styled.div`
  text-align: center;

  p {
    margin: 20px 0;
  }

  h5 {
    width: 100%;
  }

  button {
    margin-top: 5px;
  }

  .imagem-modal {
    height: 390px;
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .dados {
    float: left;
  }
`;

export const StyledBreadCrumb = styled.div`
  width: 100%;
  background-color: var(--color-third);
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;

  & > div {
    &:last-of-type, &.filtrar {
      margin-left: auto;

      label {
        margin: 0;

        span {
          color: var(--color-third-light);
        }
      }
    }
  }

  button {
    margin: 0;
    width: 315px;
    padding: 10px;
  }

  h2 {
    color: var(--color-light) !important;
    text-align: center;
    width: 100%;
    margin: 0 !important;
  }

  @media (max-width: 580px) {
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      &:last-of-type, &.filtrar {
        margin-left: 0;
      }
    }

    h2 {
      margin: 20px 0;
    }
  }
`

export const StyledEventVisualizationContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const StyledModalImage = styled.div`
  .first-of-all {
    z-index: 1001
  }
`
