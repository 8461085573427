import styled from '@emotion/styled'

export const StyledAside = styled.div`
  width: 100%;
  padding: 10px 10px 20px;
  position: relative;
  display: block;

  &::after {
    content: '';
    display: block;
    width: 80%;
    border-radius: 50%;
    height: 3px;
    position: absolute;
    top: calc(100% - 13px);
    left: 10%;
    background-color: var(--color-primary);
    opacity: .4;
    box-shadow: 2px 0px 1px 1px var(--color-third);
  }

  @media (min-width: 780px) {
    width: 400px;
    padding: 10px 40px 10px 10px;

    &::after {
      width: 2px;
      border-radius: 50%;
      height: 90%;
      top: 5%;
      left: calc(100% - 18px);
    }
  }
`
