import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import DefaultModalConfirm from '@/views/fragments/modalTypes/DefaultModalConfirm'
import { postConsultDriver, postCEPConsult, getOptionsFreightData, getCpfValid, getOptionsDriver, getCityByUfData } from '@/constants/requests'
import {
  formatterCEP,
  formatterCPF,
  formatterCPForCNPJ,
  formatterNumberOnly,
  formatterPhone
} from '@/utils/formatter'
import {
  FormContainer,
  StyledBasicData,
  StyledAddressData
} from './styles'
import SelectText from '@/views/fragments/form/SelectText'
import DefaultModalAlert from '@/views/fragments/modalTypes/DefaultModalAlert'

const MAX_LENGTH = {
  onze: 11,
  quatorze: 14
}

const habilitacaoMock = [
  {label: 'A', value: 'A'},
  {label: 'B', value: 'B'},
  {label: 'C', value: 'C'},
  {label: 'D', value: 'D'},
  {label: 'E', value: 'E'},
  {label: 'AB', value: 'AB'},
  {label: 'AC', value: 'AC'},
  {label: 'AD', value: 'AD'},
  {label: 'AE', value: 'AE'},
  {label: 'ACC', value: 'ACC'}
]

const ClientForm = (props) => {
  const {
    onChange,
    formValues,
    isEdit,
    isSubmited,
    goToDriver
  } = props

  const [disabledList, setDisabledList] = useState({})
  const [showConfirmModal, setShowConfirmModal] = useState(null)
  const [clientToShow, setClientToShow] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [statesList, setStatesList] = useState([])
  const [statesCityList, setStatesCityList] = useState([])
  const [cityList, setcityList] = useState([])
  const [validCpf, setValidCpf] = useState(false)
  const [isViacep, setIsViacep] = useState(false)

  const getFullList = useCallback(async () => {
    setIsLoading(true)
    const list = await getOptionsFreightData()
    const options = await getOptionsDriver()
    setStatesList(list.estados || [])
    setStatesCityList(options.estados || [])
    setIsLoading(false)
  });
  
  useEffect(() => {
    getFullList()
  }, [])


  const handleChange = (e, ml) => {
    const { value, name} = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value
    })
  }

  const handleChangeAddress = (e) => {
    const { value, name} = e.target

    const newValue = {
      ...formValues,
      endereco: {
        ...formValues.endereco,
        [name]: value
      }
    }

    onChange(newValue)
  }

  const handleChangeToggle = (name, bool) => {
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  const handleChangeCity = async (e, ml) => {
    const { value, name} = e.target
    setIsLoading(true)
    
    const req = await getCityByUfData(value)
    setcityList(req.cidades || [])    
    setIsViacep(false)

    if (formatterNumberOnly(value).length > ml) return

    const newValue = {
      ...formValues,
      endereco: {
        ...formValues.endereco,
        [name]: value
      }
    }

    onChange(newValue)
    setIsLoading(false)
  }

  const onBlurAddress = async () => {
    if (!formValues?.endereco?.cep) return
    const req = await postCEPConsult(formValues.endereco.cep)
    setIsViacep(true)

    const values = {
      ...formValues,
      endereco: {
        ...formValues.endereco,
        bairro: req?.dados?.bairro,
        cidade: req?.dados?.localidade,
        logradouro: req?.dados?.logradouro,
        uf: req?.dados?.uf
      }
    }

    const list = {
      bairro: Boolean(req?.dados?.bairro),
      cidade: Boolean(req?.dados?.localidade),
      logradouro: Boolean(req?.dados?.logradouro),
      uf: Boolean(req?.dados?.uf)
    }

    setDisabledList(list)
    onChange(values)
  }

  const handleBlurDocument = async () => {
    if (!formValues.cpf) return

    const doc = formatterNumberOnly(formValues.cpf)
    const req = await postConsultDriver({ cpf: doc })
    console.log(req)
    const match = req.motoristas.find((el) => el.cpf === doc)

    console.log(match)

    if (match && req.status.active) {
      setClientToShow(req.motoristas[0])
      setShowConfirmModal(true)
      return
    }

    const cpf_req = await getCpfValid(doc)
    setValidCpf(!cpf_req.cpf)
  }

  const goToRegisteredClient = () => {
    goToDriver(clientToShow)
    setShowConfirmModal(false)
  }

  const renderConfirmModal = () => (
    <DefaultModalConfirm
      msg={`Cliente com documento ${formatterCPForCNPJ(clientToShow.cpf)} já cadastrado, deseja visualizar cliente?`}
      closeModal={() => setShowConfirmModal(false)}
      callBack={goToRegisteredClient}
    />
  )
  
  const renderCpfAlertModal = () => (
    <DefaultModalAlert
      msg="CPF está invalido, verifique!"
      closeModal={() => setValidCpf(false)}
    />
  )

  const verifyIfNullOrEmpty = (value) => {
    if(value === null || value === undefined || value === "") {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {showConfirmModal && renderConfirmModal()}
      {validCpf && renderCpfAlertModal()}
      <FormContainer>
        <StyledBasicData>
          <h3>Dados Básicos</h3>
          <InputText
            value={formValues.nome || ''}
            name="nome"
            onChange={handleChange}
            label="Nome"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.nome) && isSubmited}
          />
          <InputText
            value={formatterCPF(formValues.cpf) || ''}
            name="cpf"
            onChange={(e) => handleChange(e, MAX_LENGTH.onze)}
            label="CPF"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.cpf) && isSubmited}
            isDisabled={isEdit}
            onBlur={() => handleBlurDocument(false)}
          />
          <InputText
            value={formValues.rg || ''}
            name="rg"
            onChange={handleChange}
            label="RG"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.rg) && isSubmited}
          />
          <InputText
            value={formValues.data_nascimento || ''}
            name="data_nascimento"
            type="date"
            onChange={handleChange}
            label="Aniversário"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.data_nascimento) && isSubmited}
          />
          <InputText
            value={formValues.email || ''}
            name="email"
            onChange={handleChange}
            label="Email"
          />
          <InputText
            value={formatterPhone(formValues.telefone) || ''}
            name="telefone"
            onChange={handleChange}
            label="Telefone"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.telefone) && isSubmited}
          />
          {isEdit && (
            <ToggleButton
              label="Status Ativo?"
              value={Number(formValues.status) === 1 || false}
              name="status"
              onClick={handleChangeToggle}
            />
          )}
        </StyledBasicData>
        <StyledBasicData>
          <h3>Dados CNH</h3>
          <InputText
            value={formValues.numero_cnh || ''}
            name="numero_cnh"
            onChange={handleChange}
            label="CNH"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.numero_cnh) && isSubmited}
          />
          <InputText
            value={formValues.mae || ''}
            name="mae"
            onChange={handleChange}
            label="Nome da Mãe"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.mae) && isSubmited}
          />
          <InputText
            value={formValues.pai || ''}
            name="pai"
            onChange={handleChange}
            label="Nome do Pai"
          />
          <SelectText
            value={formValues.categoria_cnh || ''}
            name="categoria_cnh"
            onChange={handleChange}
            options={habilitacaoMock}
            label="Categoria CNH"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.categoria_cnh) && isSubmited}
          />
          <InputText
            value={formValues.emissao_cnh || ''}
            name="emissao_cnh"
            type="date"
            onChange={handleChange}
            label="Data de Emissão do CNH"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.emissao_cnh) && isSubmited}
          />
          <InputText
            value={formValues.validade_cnh || ''}
            name="validade_cnh"
            type="date"
            onChange={handleChange}
            label="Data de Validade do CNH"
            isRequired
            isError={verifyIfNullOrEmpty(formValues.validade_cnh) && isSubmited}
          />
          <SelectText
            value={formValues.estado_cnh || ''}
            name="estado_cnh"
            onChange={handleChange}
            options={statesList}
            label="Estado da CNH"
            isLoading={isLoading}
          />
        </StyledBasicData>
        <StyledAddressData className='address'>
          <h3>Endereço</h3>
          <InputText
            value={formatterCEP(formValues?.endereco?.cep) || ''}
            name="cep"
            onChange={handleChangeAddress}
            label="CEP"
            onBlur={onBlurAddress}
            isDisabled={disabledList.cep}
            isRequired
            isError={verifyIfNullOrEmpty(formValues.endereco?.cep) && isSubmited}
          />

          <InputText
            value={formValues?.endereco?.logradouro || ''}
            name="logradouro"
            onChange={handleChangeAddress}
            label="Logradouro"
            isDisabled={disabledList.logradouro
              || Boolean(isEdit && formValues?.endereco?.logradouro)
            }
            isRequired
            isError={verifyIfNullOrEmpty(formValues.endereco?.logradouro) && isSubmited}
          />

          <InputText
            value={formValues?.endereco?.numero || ''}
            name="numero"
            onChange={handleChangeAddress}
            label="Número"
            isDisabled={disabledList.numero}
            isRequired
            isError={verifyIfNullOrEmpty(formValues.endereco?.numero) && isSubmited}
          />

          <InputText
            value={formValues?.endereco?.bairro || ''}
            name="bairro"
            onChange={handleChangeAddress}
            label="Bairro"
            isDisabled={disabledList.bairro
              || Boolean(isEdit && formValues?.endereco?.bairro)
            }
            isRequired
            isError={verifyIfNullOrEmpty(formValues.endereco?.bairro) && isSubmited}
          />
          
          <SelectText
            value={formValues?.endereco?.uf || ''}
            name="uf"
            onChange={handleChangeCity}
            label="UF"
            options={statesCityList}
            isLoading={isLoading}
            isDisabled={disabledList.uf
              || Boolean(isEdit && formValues?.endereco?.uf)
            }
            isRequired
            isError={verifyIfNullOrEmpty(formValues.endereco?.uf) && isSubmited}
          />
          
          {(isViacep || isEdit) && (
            <InputText
              value={formValues?.endereco?.cidade || ''}
              name="cidade"
              onChange={handleChangeAddress}
              label="Cidade"
              isDisabled={disabledList.cidade
                || Boolean(isEdit && formValues?.endereco?.cidade)
              }
              isRequired
              isError={verifyIfNullOrEmpty(formValues.endereco?.cidade) && isSubmited}
            />
          )}
          
          {(!isViacep && !isEdit) && (
            <SelectText
              value={formValues?.endereco?.cidade || ''}
              name="cidade"
              onChange={handleChangeAddress}
              label="Cidade"
              options={cityList}
              isDisabled={disabledList.cidade
                || Boolean(isEdit && formValues?.endereco?.cidade)
              }
              isRequired
              isError={verifyIfNullOrEmpty(formValues.endereco?.cidade) && isSubmited}
            />
          )}

          <InputText
            value={formValues?.endereco?.complemento || ''}
            name="complemento"
            onChange={handleChangeAddress}
            label="Complemento"
          />
        </StyledAddressData>
      </FormContainer>
    </>
  )
}

ClientForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goToDriver: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool,
  isSubmited: PropTypes.bool,
}

ClientForm.defaultProps = {
  isEdit: false,
  isSubmited: false,
  goToDriver: null
}

export default ClientForm
