import styled from '@emotion/styled';

export const StyledEvents = styled.div`
  width: 35%;
  border: 1px solid var(--color-primary);

  .content {
    padding: 0 10px;
  }

  .form-container {
    border-top: 1px solid var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);
    padding: 15px 0 0;

    textarea {
      margin: 0;
    }

    button {
      margin-top: 0;
    }
  }
`

export const StyledListEvents = styled.ul`
  display: block;
  width: 100%;
  background-color: var(--color-gray-lighter);
  margin: 20px 0;

  li {
    padding: 10px;
    border-top: 1px solid var(--color-third);
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    &:first-of-type {
      border-top: none;
    }

    & > div {

      span {
        display: block;
        width: 100%;

        &:first-of-type {
          font-weight: 700;
          font-size: 11px;
          text-transform: uppercase;
          margin-bottom: 4px;
          color: var(--color-gray-dark);
        }
      }
    }
  }
`
