import styled from '@emotion/styled'

export const StyledBodyContainer = styled.section`
  button {
    &.formButton {
      float: right;
    }
  }
`

export const StyledBreadCrumb = styled.div`
  width: 100%;
  background-color: var(--color-third);
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;

  & > div {
    &:last-of-type {
      margin-left: auto;

      label {
        margin: 0;

        span {
          color: var(--color-third-light);
        }
      }
    }
  }

  button {
    margin: 0;
    width: 315px;
    padding: 10px;
  }

  h2 {
    color: var(--color-light) !important;
    text-align: center;
    width: 100%;
    margin: 0 !important;
  }

  @media (max-width: 580px) {
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      &:last-of-type {
        margin-left: 0;
      }
    }

    h2 {
      margin: 20px 0;
    }
  }
`