import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ElementContent, ListElement, StyledModal } from './styles'
import { formatterMoney, } from '@/utils/formatter'
import Modal from '@/views/fragments/Modal'
import TextArea from '@/views/fragments/form/TextArea'
import SelectText from '@/views/fragments/form/SelectText'
import InputText from '@/views/fragments/form/InputText'
import { freightActions } from '@/constants/optionsValues'
import {
  getDriversFreight,
  putDriverFreightData,
  postEventsList,
  postFreightCancel,
  postFreightFinalize,
  postFreightInitialize,
  postFreightAlert,
  postGenerateReport,
  getFreightOffer,
  postValidateFreight
} from '@/constants/requests'
import DefaultModalAlert from '../../../fragments/modalTypes/DefaultModalAlert'
import { defaultPath } from "@/utils/paths"
import { StyledButtons } from './styles'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const INNER_WIDTH_PARAM = 1280

const FreightList = (props) => {
  const {
    fullList,
    onEditItem,
    handleSearch,
  } = props

  const [selectedId, setSelectedId] = useState(null)
  const [justificativa, setJustificativa] = useState(null)
  const [tempo1, setTempo1] = useState(null)
  const [tempo2, setTempo2] = useState(null)
  const [editModal, showEditModal] = useState(false)
  const [showDrivers, setShowDrivers] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showJustificativa, setShowJustificativa] = useState(false)
  const [showTable, setShowTable] = useState(window.innerWidth >= INNER_WIDTH_PARAM)
  const [selectedDriver, setSelectedDriver] = useState(null)
  const [actionOption, setActionOption] = useState(null)
  const [optionsDrivers, setOptionsDrivers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [freightActionsOptions, setFreightActionsOptions] = useState([])
  const [habilitar, setHabilitar] = useState(false)
  const [ofertasFrete, setOfertasFrete] = useState([])
  const [statusOferta, setStatusOferta] = useState(false)
  const urlToDownload = `${defaultPath}/Relatorios/rodorrica.PDF`;

  const getDrivers = useCallback(async () => {
    const drivers = await getDriversFreight()
    let obj = drivers.motoristas
    for (let i = 0; i < obj.length; i++) {
      const item = obj[i];
      delete Object.assign(item, {["id"]: item["value"] })["value"];
      delete Object.assign(item, {["name"]: item["label"] })["label"];
    }
    setOptionsDrivers(obj)
  }, [])  

  useEffect(() => {
    window.addEventListener('resize', () => {
      setShowTable(window.innerWidth >= INNER_WIDTH_PARAM)
    })
    getDrivers()
  }, [])

  const onSelectDriverClick = (id) => {
    setSelectedId(id)
    const item = fullList.find((el) => el.id_frete === id)
    
    setTempo1(item.tempo1)
    setTempo2(item.tempo2)

    let action = []
    if(item.status === 2) {
      action = freightActions.filter((el) => el.value === 5 || el.value === 4 || el.value === 9)
    } else if(item.status === 1) {
      action = freightActions.filter((el) => el.value === 4 || el.value === 9)
    } else if(item.status === 3) {
      action = freightActions.filter((el) => el.value === 8 || el.value === 7 || el.value === 6)
    } else if(item.status === 4) {
      action = freightActions.filter((el) => el.value === 8)
    } else if(item.status === 5) {
      action = freightActions.filter((el) => el.value === 8)
    }

    setFreightActionsOptions(action) 
    showEditModal(true)
  }

  const onOfferDriver = async (frete) => {
    let response = await getFreightOffer(frete)
    
    setOfertasFrete(response.ofertas_motoristas)
    setShowOfferModal(true)
  }

  const confirmDriver = (offer, status) => {
    onCloseModal()

    let body = {
      "id_motorista_ofertado": offer.id_motorista_ofertado,
      "id_frete": offer.id_frete,
      "id_motorista": offer.id_motorista,
      "status": status
    }

    setStatusOferta(body)
    setShowConfirmModal(true)
  }

  const setValidateOffer = async (confirm) => {
    if (confirm) {
      await postValidateFreight(statusOferta)
      onCloseModal()
      handleSearch()
    } else {
      onCloseModal()
    }
  }

  const onCloseModal = () => {
    clearAction()
    showEditModal(false)
    setShowModal(null)
    setShowOfferModal(false)
    setShowConfirmModal(false)
    setActionOption(null)
    setShowDrivers(false)
    setShowAlert(false)
  }

  const onChangeDriver = (e) => {
    setSelectedDriver(e)
  }

  const onChangeAction = (e) => {
    const {value} = e.target
    setActionOption(value)
    setShowDrivers(false)
    setShowAlert(false)
    setShowJustificativa(false)

    if(Number(value) !== 9 && Number(value) !== 7 && Number(value) !== 8) {
      setShowJustificativa(true)
    }

    if(Number(value) === 9) {
      setShowDrivers(true)
    } else if (Number(value) === 7) {
      setShowAlert(true)
    }
  }

  const onChangeJustificativa = (e) => {
    const {value} = e.target
    setJustificativa(value)    
  }

  const onChangeTempo1 = (e) => {
    const {value} = e.target
    setTempo1(value)    
  }

  const onChangeTempo2 = (e) => {
    const {value} = e.target
    setTempo2(value)    
  }

  const onSaveClick = async() => {
    setHabilitar(true)
    if(Number(actionOption) === 9) {
      onAssociateClick()
    } else if (Number(actionOption) === 4) {
      onCancelClick()
    } else if (Number(actionOption) === 5) {
      onInitializeClick()
    } else if (Number(actionOption) === 6) {
      onFinalizeClick()
    } else if (Number(actionOption) === 7) {
      onAlertClick()
    } else if (Number(actionOption) === 8) {
      onReportClick()
    }
  }

  const clearAction = () => {
    setSelectedDriver(null)
    setActionOption(null)
    setShowDrivers(false)
    setShowAlert(false)
    setShowJustificativa(false)
    setJustificativa(null)
    setTempo1(null)
    setTempo2(null)
    setStatusOferta(null)
    showEditModal(false)
  }

  const onAssociateClick = async () => {
    const item = fullList.find((el) => el.id_frete === selectedId)
    const driver = optionsDrivers.find((el) => el.id === selectedDriver.id)
    const newForm = {
      ...item,
      descricao: driver.label,
      tipo_evento: actionOption,
      id_motorista: Number(selectedDriver.id),
      motorista: driver.label,
    }

    const put = await putDriverFreightData(newForm)
    console.log(put)
    const req = await postEventsList(newForm)
    
    setHabilitar(false)
    if (req?.status?.active) {
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Frete associado com sucesso!")
      return
    }
  }

  const onFinalizeClick = async () => {
    const item = fullList.find((el) => el.id_frete === selectedId)
    const newForm = {
      ...item,
      descricao: justificativa,
      tipo_evento: actionOption,
      id_frete: selectedId,
    }
    const post = await postFreightFinalize(newForm)
    console.log(post)
    const req = await postEventsList(newForm)
    
    setHabilitar(false)
    if (req?.status?.active) {
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Frete finalizado com sucesso!")
      return
    }
  }

  const onAlertClick = async () => {
    const item = fullList.find((el) => el.id_frete === selectedId)
    const newForm = {
      ...item,
      alerta: 1,
      descricao: "Tempo de alerta mudado",
      tipo_evento: actionOption,
      tempo1: tempo1,
      tempo2: tempo2,
      id_frete: selectedId,
    }
    const post = await postFreightAlert(newForm)
    
    setHabilitar(false)
    if (post?.status?.active) {
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Tempo de alerta mudado com sucesso!")
      return
    }
  }

  const onReportClick = async () => {
    const post = await postGenerateReport(selectedId)
    console.log(post)
    
    setHabilitar(false)
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Relatório gerado com sucesso, abrirá em uma nova guia!")
      setTimeout(() => {
        window.open(urlToDownload, '_blank');
      }, 1000);
      return
    
  }

  const onCancelClick = async () => {
    const item = fullList.find((el) => el.id_frete === selectedId)
    const newForm = {
      ...item,
      descricao: justificativa,
      tipo_evento: actionOption,
      id_frete: selectedId,
    }
    const post = await postFreightCancel(newForm)
    console.log(post)
    const req = await postEventsList(newForm)
    
    setHabilitar(false)
    if (req?.status?.active) {
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Frete cancelado com sucesso!")
      return
    }
  }

  const onInitializeClick = async () => {
    const item = fullList.find((el) => el.id_frete === selectedId)
    const newForm = {
      ...item,
      descricao: justificativa,
      tipo_evento: actionOption,
      id_frete: selectedId,
    }
    const post = await postFreightInitialize(newForm)
    console.log(post)
    const req = await postEventsList(newForm)
    
    setHabilitar(false)
    if (req?.status?.active) {
      handleSearch()
      clearAction()
      showEditModal(false)
      setShowModal(true)
      setShowMessageModal("Frete iniciado com sucesso!")
      return
    }
  }

  const handleOnSelect = (item) => {
    onChangeDriver(item)
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }
  
  const renderEditModal = () => (
    <Modal
      closeModal={onCloseModal}
    >
      <StyledModal>
        <p>Selecione uma ação para o frete de número <b>{selectedId}</b></p>
        <SelectText
          label="Ação"
          name="action"
          onChange={onChangeAction}
          options={freightActionsOptions}
          value={actionOption || ''}
        />
        {showDrivers && (
          <div className='margin'>
            <span className='label-input'>Motorista</span>
            <ReactSearchAutocomplete
              items={optionsDrivers}
              onSelect={handleOnSelect}
              autoFocus
              formatResult={formatResult}
              showIcon={false}
              showClear={false}
              style={{
                marginTop: "0px !important",
              }}
              styling={{
                height: "40px",
                borderRadius: "5px",
                backgroundColor: "#e9f5ff",
                color: "black",
                border: "none",
                boxShadow: "3px 3px 0 0 #5ca2df",
                padding: "0 10px",
                fontSize: "16px",
                fontWeight: "800",
                zIndex: 2,
              }}
            />
          </div> 
        )}
        {showJustificativa && (
          <TextArea
            name="justificativa"
            label="Justificativa"
            value={justificativa || ''}
            onChange={onChangeJustificativa}
          />
        )}
        {showAlert && (   
          <InputText
            label={"Tempo 1"}
            name={"tempo1"}
            type='time'
            value={tempo1 || ''}
            onChange={onChangeTempo1}
          />
        )}
        {showAlert && (      
          <InputText
            label={"Tempo 2"}
            name={"tempo2"}
            type='time'
            value={tempo2 || ''}
            onChange={onChangeTempo2}
          />
        )}
        <button className='button' disabled={habilitar} onClick={onSaveClick}>
          Salvar
        </button>
      </StyledModal>
    </Modal>
  )

  const renderMobileList = (item) => (
    <div className='row' key={item.id_frete}>
      <div>
        <span>Frete</span>
        <p>{item.id_frete || '-'}</p>
      </div>
      <div className='name'>
        <span>Cliente</span>
        <p>{item.cliente || '-'}</p>
      </div>
      <div className='name'>
        <span>Origem</span>
        <p>{item.origem_cidade_texto || '-'}</p>
      </div>
      <div className='name'>
        <span>Destino</span>
        <p>{item.destino_cidade_texto || '-'}</p>
      </div>
      <div>
        <span>Valor Contratado / Acordado - Motorista</span>
        <p>R$ {formatterMoney(item.preco) || '-'}</p>
      </div>
      <div>
        <span>Valor da Mercadoria / Transportado</span>
        <p>R$ {formatterMoney(item.preco_mercadoria) || '-'}</p>
      </div>
      <div>
        <span>Status</span>
        <p>{item.status_frete || '-'}</p>
      </div>
      <div>
        <button type='button' onClick={() => onSelectDriverClick(item.id_frete)}>
          <i className="fas fa-truck-moving" />
          <i className="far fa-eye" />
        </button>
      </div>
      <div>
        <button type='button' onClick={() => onEditItem(item)} disabled={( Number(item.status) === 3 || Number(item.status) === 6)}>
          <i className="fas fa-pencil" />
        </button>
      </div>
    </div>
  )

  const  renderOfferList = (item, index) => (
    <li key={index}>
      <div>
        <p>{item.id_frete || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.motorista || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.cpf || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.distancia || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.status_oferta || '-'}</p>
      </div>
      <div>
        {
          (Number(item.status) !== 2) 
          ? 
          <button style={{backgroundColor: "gray", border: "gray"}} disabled={true}>
            <i className="fas fa-check" />
          </button>          
          : 
          <button type='button' onClick={() => confirmDriver(item, 7)} disabled={(Number(item.status) !== 2)}>
            <i className="fas fa-check" />
          </button>
        }
      </div>
      <div>
        {
          (Number(item.status) !== 2) 
          ? 
          <button style={{backgroundColor: "gray", border: "gray"}} disabled={true}>
            <i className="fas fa-x" />
          </button>          
          : 
          <button type='button' onClick={() => confirmDriver(item, 6)} disabled={(Number(item.status) !== 2)}>
            <i className="fas fa-x" />
          </button>
        }
      </div>
    </li>
  )

  const renderList = (item, index) => (
    <li key={index}>
      <div>
        <p>{item.id_frete || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.cliente || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.origem_cidade_texto || '-'}</p>
      </div>
      <div className='name'>
        <p>{item.destino_cidade_texto || '-'}</p>
      </div>
      <div>
        <p>R$ {formatterMoney(item.preco) || '-'}</p>
      </div>
      <div>
        <p>R$ {formatterMoney(item.preco_mercadoria) || '-'}</p>
      </div>
      <div>
        <p>{item.status_frete || '-'}</p>
      </div>
      <div>
        {
          (Number(item.ofertas) < 1 || Number(item.status) !== 1) 
          ? 
          <button style={{backgroundColor: "gray", border: "gray"}} disabled={true}>
            <i className="fas fa-truck-moving" />
          </button>          
          : 
          <button type='button' onClick={() => onOfferDriver(item.id_frete)} disabled={Number(item.ofertas) < 1}>
            <i className="fas fa-truck-moving" />
          </button>
        }
      </div>
      <div>
        <button type='button' onClick={() => onSelectDriverClick(item.id_frete)}>
          <i className="far fa-eye" />
        </button>
      </div>
      <div>
        {
          (Number(item.status) === 4 || Number(item.status) === 3 || Number(item.status) === 6) 
          ? 
          <button style={{backgroundColor: "gray", border: "gray"}} disabled={true}>
            <i className="fas fa-pencil" />
          </button>          
          : 
          <button type='button' onClick={() => onEditItem(item)} disabled={(Number(item.status) === 4 || Number(item.status) === 3 || Number(item.status) === 6)}>
            <i className="fas fa-pencil" />
          </button>
        }
        
      </div>
    </li>
  )

  return (
    <ElementContent>
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg={showMessageModal}
        />
      )}
      {showConfirmModal && (
        <Modal
        closeModal={() => onCloseModal}
        subTitle='Deseja confirmar sua escolha?'
        >
          <StyledButtons>
            <button
              className='button cancel'
              onClick={() => setValidateOffer(false)}
            >Não</button>
            <button
              className='button'
              onClick={() => setValidateOffer(true)}
            >Sim</button>
          </StyledButtons>
        </Modal>
      )}
      {showOfferModal && (
        <Modal
          closeModal={onCloseModal}
          subTitle="Oferta Motoristas ao Frete"
          size="lg"
        >
          <ListElement>
            <ul className='lista-ofertas'>
              <li className="table-header">
                <div><span>Frete</span></div>
                <div><span>Motorista</span></div>
                <div><span>CPF</span></div>
                <div><span>Distância</span></div>
                <div><span>Status</span></div>
                <div><span>Aceitar</span></div>
                <div><span>Rejeitar</span></div>
              </li>
              {ofertasFrete.map((item, index) => renderOfferList(item, index))}
            </ul>
          </ListElement>
        </Modal>
      )}
      {editModal && renderEditModal()}
      {showTable ? (
        <ListElement>
          <ul>
            <li className="table-header">
              <div><span>Frete</span></div>
              <div><span>Cliente</span></div>
              <div><span>Origem</span></div>
              <div><span>Destino</span></div>
              <div><span>Valor Contratado / Acordado - Motorista</span></div>
              <div><span>Valor da Mercadoria / Transportado</span></div>
              <div><span>Status</span></div>
              <div><span>Ofertas</span></div>
              <div><span>Ações</span></div>
              <div><span>Editar</span></div>
            </li>
            {fullList.map((item, index) => renderList(item, index))}
          </ul>
        </ListElement>
      ) : (
        <ListElement>
          {fullList.map((item, index) => renderMobileList(item, index))}
        </ListElement>
      )}
    </ElementContent>
  )
}

FreightList.propTypes = {
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditItem: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  showFullList: PropTypes.number
}

FreightList.defaultProps = {
  showFullList: null
}

export default FreightList
