import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import { getCNPJInfo, postCEPConsult, getConsultParent } from '@/constants/requests'
import DefaultModalAlert from '@/views/fragments/modalTypes/DefaultModalAlert'
import DefaultModalConfirm from '@/views/fragments/modalTypes/DefaultModalConfirm'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import {
  formatterCEP,
  formatterCNPJ,
  formatterNumberOnly,
  formatterCPForCNPJ,
  formatterPhone
} from '@/utils/formatter'
import {
  FormContainer,
  StyledBasicData,
  StyledAddressData
} from './styles'

const MAX_LENGTH = {
  onze: 11,
  quatorze: 14
}

const FormEdit = (props) => {
  const {
    onChange,
    subsidiary,
    isEdit,
    goToParent,
  } = props

  const [showAlertModal, setShowAlertModal] = useState(false)
  const [disabledList, setDisabledList] = useState(false)
  const [parentToShow, setParentToShow] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(null)

  const handleChange = (e, ml) => {
    const { value, name} = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...subsidiary,
      [name]: value
    })
  }

  const handleChangeSigla = (e) => {
    if (e.target.value.length >= 4) return

    onChange({
      ...subsidiary,
      sigla: e.target.value.toUpperCase()
    })
  }

  const handleChangeAddress = (e) => {
    const { value, name} = e.target

    const newValue = {
      ...subsidiary,
      endereco: {
        ...subsidiary.endereco,
        [name]: value
      }
    }

    onChange(newValue)
  }

  const handleChangeToggle = (name, bool) => {
    onChange({
      ...subsidiary,
      [name]: bool ? 1 : 0
    })
  }

  const onBlurAddress = async () => {
    const req = await postCEPConsult(subsidiary.endereco.cep)

    const values = {
      ...subsidiary,
      endereco: {
        ...subsidiary.endereco,
        bairro: req.dados.bairro,
        cidade: req.dados.localidade,
        logradouro: req.dados.logradouro,
        uf: req.dados.uf
      }
    }

    const list = {
      bairro: Boolean(req?.dados?.bairro),
      cidade: Boolean(req?.dados?.localidade),
      logradouro: Boolean(req?.dados?.logradouro),
      uf: Boolean(req?.dados?.uf)
    }

    setDisabledList(list)
    onChange(values)
  }

  const handleBlurDocument = async () => {
    if (!subsidiary.doc_number) return

    const doc = formatterNumberOnly(subsidiary.doc_number)

    const req = await getConsultParent( doc )
    const match = req.matrizes.find((el) => el.doc_number === doc)

    if (match && req.status.active) {
      setParentToShow(req.matrizes[0])
      setShowConfirmModal(true)
      return
    }
    const cnpj = await getCNPJInfo(doc)
    
    if (!cnpj.status.active) {
      setShowAlertModal(true)
      return
    }    

    const list = {
      bairro: Boolean(cnpj?.empresa?.bairro),
      cidade: Boolean(cnpj?.empresa?.municipio),
      logradouro: Boolean(cnpj?.empresa?.logradouro),
      uf: Boolean(cnpj?.empresa?.uf)
    }
    setDisabledList(list)

    const form = {
      ...subsidiary,
      email: cnpj.empresa.email,
      endereco: {
        uf: cnpj.empresa.uf,
        cidade: cnpj.empresa.municipio,
        complemento: cnpj.empresa.complemento,
        numero: cnpj.empresa.numero,
        bairro: cnpj.empresa.bairro,
        logradouro: cnpj.empresa.logradouro,
        cep: cnpj.empresa.cep
      },
      nome_fantasia: cnpj.empresa.fantasia,
      razao_social: cnpj.empresa.nome,
      telefone: cnpj.empresa.telefone,
    }

    onChange(form)
  }

  const onCloseModal = () => {
    setShowAlertModal(false)
  }

  const renderAlertModal = () => (
    <DefaultModalAlert
      closeModal={onCloseModal}
      msg="CNPJ Não encontrado"
    />
  )

  const renderConfirmModal = () => (
    <DefaultModalConfirm
      msg={`Filial com documento ${formatterCPForCNPJ(parentToShow.doc_number)} já cadastrado, deseja visualizar filial?`}
      closeModal={() => setShowConfirmModal(false)}
      callBack={goToRegisteredDriver}
    />
  )

  const goToRegisteredDriver = () => {
    goToParent(parentToShow)
    setShowConfirmModal(false)
  }

  return (
    <FormContainer>
      {showConfirmModal && renderConfirmModal()}
      {showAlertModal && renderAlertModal()}
      <StyledBasicData>
        <h3>Dados Básicos</h3>
        <InputText
          value={formatterCNPJ(subsidiary.doc_number) || ''}
          name="doc_number"
          onChange={(e) => handleChange(e, MAX_LENGTH.quatorze)}
          label="CNPJ"
          isDisabled={isEdit}
          onBlur={() => handleBlurDocument(true)}
        />
        <InputText
          value={subsidiary.inscricao_estadual || ''}
          name="inscricao_estadual"
          onChange={handleChange}
          label="Inscrição Estadual"
        />
        <InputText
          value={subsidiary.nome_fantasia || ''}
          name="nome_fantasia"
          onChange={handleChange}
          label="Nome Fantasia"
        />
        <InputText
          value={subsidiary.razao_social || ''}
          name="razao_social"
          onChange={handleChange}
          label="Razão Social"
        />
        <InputText
          value={subsidiary.sigla || ''}
          name="sigla"
          onChange={handleChangeSigla}
          label="Sigla"
        />
        <InputText
          value={subsidiary.email || ''}
          name="email"
          onChange={handleChange}
          label="Email"
        />
        <InputText
          value={formatterPhone(subsidiary.telefone) || ''}
          name="telefone"
          onChange={handleChange}
          label="Telefone"
        />
        {isEdit && subsidiary.matriz === 0 && (
          <ToggleButton
            label="Status Ativo?"
            value={subsidiary.status === 1 || false}
            name="status"
            onClick={handleChangeToggle}
          />
        )}
      </StyledBasicData>
      <StyledAddressData>
        <h3>Endereço</h3>
        <InputText
          value={formatterCEP(subsidiary?.endereco?.cep) || ''}
          name="cep"
          onChange={handleChangeAddress}
          label="CEP"
          onBlur={onBlurAddress}
        />
        <InputText
          value={subsidiary?.endereco?.logradouro || ''}
          name="logradouro"
          onChange={handleChangeAddress}
          label="Logradouro"
          isDisabled={disabledList.logradouro}
        />
        <InputText
          value={subsidiary?.endereco?.numero || ''}
          name="numero"
          onChange={handleChangeAddress}
          label="Número"
        />
        <InputText
          value={subsidiary?.endereco?.bairro || ''}
          name="bairro"
          onChange={handleChangeAddress}
          label="Bairro"
          isDisabled={disabledList.bairro}
        />
        <InputText
          value={subsidiary?.endereco?.cidade || ''}
          name="cidade"
          onChange={handleChangeAddress}
          label="Cidade"
          isDisabled={disabledList.cidade}
        />
        <InputText
          value={subsidiary?.endereco?.uf || ''}
          name="uf"
          onChange={handleChangeAddress}
          label="UF"
          isDisabled={disabledList.uf}
        />
        <InputText
          value={subsidiary?.endereco?.complemento || ''}
          name="complemento"
          onChange={handleChangeAddress}
          label="Complemento"
        />
      </StyledAddressData>
    </FormContainer>
  )
}

FormEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  subsidiary: PropTypes.objectOf(PropTypes.any).isRequired,
  goToParent: PropTypes.func,
  isEdit: PropTypes.bool
}

FormEdit.defaultProps = {
  isEdit: false,
  goToParent: null,
}

export default FormEdit
