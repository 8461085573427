import React from 'react'
import PropTypes from 'prop-types'
import { ElementContent } from './styles'

const google = window.google

const MapsList = (props) => {
  const {
    handleImageModal,
    mapConfig,
    shouldUpdate,
    handleUpdate
  } = props

  const handleMaps = (endpoint, freight) => {
    let bounds = new google.maps.LatLngBounds()

    const map = new google.maps.Map(document.getElementById('map_canvas'), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    })

    endpoint.mapafretes.forEach((value) => {
      if((value.lat !== "0" && value.lng !== "0")) {
        let myLatlng = new google.maps.LatLng(value.lat, value.lng)
        let marker, loc
        // const URL = 'https://chart.googleapis.com/chart?chst=d_map_pin_letter&chld='

        // if (freight) {
        //   if (value.macro !== 'Coleta Automatica') {
        //     image = `${URL}`
        //   } else {
        //     image = `${URL}`
        //   }
        // } else {
        //   image = `${URL}${value.frete}`
        // }

        // console.log(image)

        console.log(freight)
        
        if (freight) {
          let icon = {
            url: "https://i.ibb.co/gvsWvqZ/4897637-location-red-icon-simple-design-gratis-vetor-removebg-preview.png",
            origin: new google.maps.Point(0,0), 
            anchor: new google.maps.Point(0, 0) ,
            scaledSize: new google.maps.Size(46, 46),
          };

          marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            icon: icon,
          })  
        } else {
          let icon = {
            url: "https://i.ibb.co/fnqg2C7/truckz.png",  
            origin: new google.maps.Point(0,0), 
            anchor: new google.maps.Point(0, 0) ,
            scaledSize: new google.maps.Size(25, 17),
          };
          
          marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            icon: icon,
            title: 'Frete: ' + value.frete
          })  
        }
        

        let infoContent = '<strong>' + 'Frete: ' + value.frete + '</strong>'
        infoContent += '<p>' + 'Cliente: ' + value.cliente + '</p>'
        infoContent +=
          '<p>' + 'Data de recebimento: ' + value.data_recebimento + '</p>'
        infoContent +=
          '<p>' + 'Geolocalização: ' + value.lat + ',' + value.lng + '</p>'
        if (freight) {
          infoContent += '<p>' + 'Macro de numero: ' + (value.controle + 1) + '</p>'
        }
        infoContent += '<p>' + 'Macro: ' + value.macro + '</p>'
        if (value.macro === 'Mensagem Livre') {
          infoContent += '<p>' + 'Mensagem: ' + value.mensagem_livre + '</p>'
        }

        marker.info = new google.maps.InfoWindow({
          content: infoContent,
        })

        google.maps.event.addListener(marker, 'click', function () {
          marker.info.open(map, marker)
        })

        if (freight && value.macro !== 'Coleta Automatica') {
          google.maps.event.addListener(marker, 'click', function () {
            handleImageModal(value)
          })
        }

        if (freight) {
          if (value.macro !== 'Coleta Automatica') {
            marker.setAnimation(google.maps.Animation.BOUNCE)
          }
        }

        loc = new google.maps.LatLng(
          marker.position.lat(),
          marker.position.lng()
        )

        bounds.extend(loc)
        map.fitBounds(bounds)
        map.panToBounds(bounds)
      }
    })

    if (freight) {
      var coordinates = []

      endpoint.mapafretes.forEach((value) => {
        var object = {
          lat: Number(value.lat),
          lng: Number(value.lng),
        }
        coordinates.push(object)
      })

      const freight = new google.maps.Polyline({
        path: coordinates,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      })

      freight.setMap(map)
    }
  }

  return (
    <ElementContent>
      <div id='map_canvas'/>
      {shouldUpdate && mapConfig && (
        <>
          {handleMaps(mapConfig.endpoint, mapConfig.freight)}
          {handleUpdate(false)}
        </>
      )}
    </ElementContent>
  )
}

MapsList.propTypes = {
  handleImageModal: PropTypes.func.isRequired,
  mapConfig: PropTypes.any,
  shouldUpdate: PropTypes.bool.isRequired,
  handleUpdate: PropTypes.func.isRequired
}

MapsList.defaultProps = {
  mapConfig: null,
}

export default MapsList
