import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Field,
  CustomLoading,
  StyledHint,
  StyledMultiple
} from './styles'

const SelectMultiple = (props) => {
  const {
    label,
    values,
    onChange,
    options,
    isRequired,
    isDisabled,
    className,
    isLoading,
    isError,
    isSuccess,
    hint,
  } = props

  const [showOptions, setShowOptions] = useState(false)
  const [toShowOptions, setToShowOptions] = useState([])
  const [selectedList, setSelectedList] = useState([])
  const [inputFilter, setInputFilter] = useState('')

  useEffect(() => {
    const list = [...options]
    setToShowOptions(list)
    const selected = []
    values.map((id) => {
      list.map((el) => {
        if (el.value === id) {
          selected.push(el)
        }
      })
    })
    setSelectedList(selected)
  }, [setToShowOptions, options])

  const onSelectItem = (item) => {
    const list = [...selectedList]
    list.push(item)
    setSelectedList(list)
    onChange(list)
  }

  const renderType = () => {
    if (isSuccess) return 'success'
    if (isError) return 'alert'
    return ''
  }

  const handleChange = (e) => {
    const list = [...options]
    const newList = list.filter((el) => {
      return el.label.toLowerCase().includes(e.target.value)
    })
    setToShowOptions(newList)
    setInputFilter(e.target.value)
    setShowOptions(true)
  }

  const handleRemove = (el) => {
    const list = [...selectedList]
    const index = list.findIndex((item) => el.value === item.value)
    list.splice(index, 1)
    setSelectedList(list)
    onChange(list)
  }

  const renderOptions = (item) => {
    const has = selectedList.find((e) => item.value === e.value)
    if (has) return

    return(
      <li onClick={() => onSelectItem(item)} key={item.value}>
        {item.label}
      </li>
    )
  }


  const renderSelecteds = (el) => (
    <span key={el.value}>
      {el.label}
      <button onClick={() => handleRemove(el)}>
        <i className="fas fa-times" />
      </button>
    </span>
  )


  return (
    <>
      <Field className={className}>
        <span>
          {label}
          {isRequired && (
            <i className="fas fa-asterisk" title="campo obrigatorio"/>
          )}
        </span>
      </Field>
      <StyledMultiple>
        {Boolean(selectedList.length) && (
          <div className='sected-items'>
            {selectedList.map((el) => renderSelecteds(el))}
          </div>
        )}
      </StyledMultiple>
      <Field className={className}>
        <StyledMultiple>
          <div className='input-multiple'>
            <input
              name="inputFilter"
              value={inputFilter}
              onChange={handleChange}
              className={renderType()}
              required={isRequired}
              disabled={isDisabled || isLoading}
            />
            <button onClick={() => setShowOptions(!showOptions)}>
              <i className="fas fa-chevron-down" />
            </button>
          </div>
          {showOptions && (
            <ul>
              {toShowOptions.map((el) => renderOptions(el))}
            </ul>
          )}
        </StyledMultiple>
        {isLoading && (
          <CustomLoading className="fas fa-spinner" />
        )}
        {(isError && !isSuccess) && (
          <StyledHint className={isSuccess ? 'success' : ''}>
            {hint}
          </StyledHint>
        )}
      </Field>
    </>
  )
}

SelectMultiple.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hint: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

SelectMultiple.defaultProps = {
  isRequired: false,
  isDisabled: false,
  className: '',
  isLoading: false,
  hint: '',
  isError: false,
  isSuccess: false,
}

export default SelectMultiple
