import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SelectText from '@/views/fragments/form/SelectText'
import DefaultModalAlert from '@/views/fragments/modalTypes/DefaultModalAlert'
import { postEventsList } from '@/constants/requests'
import { StyledBreadCrumb } from '@/views/Monitor/styles'
import { format } from 'date-fns'
import { StyledEvents, StyledListEvents } from './styles'
import TextArea from '@/views/fragments/form/TextArea'

const AddEvents = (props) => {
  const {
    freightOrder,
    listEvents,
    freightId,
    feedBackUpdade
  } = props

  const [eventForm, setEventForm] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showMessage, setShowMessage] = useState(null)
  const [showModalRequired, setShowModalRequired] = useState(false)
  const [requiredMessage, setRequiredMessage] = useState('')
  
  const verifyIfNullOrEmpty = (value) => {
    if(value === null || value === undefined || value === "") {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = async () => {
    const params = {
      ...eventForm,
      id_frete: freightId
    }
    if(verifyIfNullOrEmpty(params.tipo_evento)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo tipo de evento!')
      return true    
    } 
    if(verifyIfNullOrEmpty(params.descricao)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo descrição!')
      return true    
    } 
    const req = await postEventsList(params)
    if (req?.status?.active) {
      setEventForm('')
      feedBackUpdade()
      setShowMessage(req?.status?.message)
      setShowForm(false)
      return
    }

    setShowMessage('Algo deu errado, contate um administrador se o erro persistir')
  }

  const onChangeForm = (e) => {
    const { name, value } = e.target
    setEventForm({
      ...eventForm,
      [name]: value
    })
  }

  const renderListEvent = (el, i) => (
    <li key={i}>
      <div>
        <span>Evento</span>
        <span>{el.tipo_evento}</span>
      </div>
      <div>
        <span>Descrição</span>
        <span>{el.descricao}</span>
      </div>
      <div>
        <span>Data e Hora</span>
        <span>{format(new Date(el.data), 'dd/MM/yy HH:mm')}</span>
      </div>
      <div>
        <span>Quem adicionou</span>
        <span>{el.nome}</span>
      </div>
      <div>
        <span>frete</span>
        <span>{el.frete}</span>
      </div>
    </li>
  )

  const handleAdd = () => {
    setShowForm(true)
  }

  const handleCloseMessage = () => {
    setShowMessage(null)
  }

  const renderModalMessage = () => (
    <DefaultModalAlert
      closeModal={handleCloseMessage}
      msg={showMessage}
    />
  )
  
  const onCloseModal = () => {
    setShowModalRequired(null)
    setRequiredMessage('')
  }

  return (
    <StyledEvents>
      {showMessage && renderModalMessage()}
      {showModalRequired && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg={requiredMessage}
        />
      )}
      <StyledBreadCrumb>
        <h2>Eventos</h2>
      </StyledBreadCrumb>
      <div className='content'>
        <button className='button' onClick={handleAdd}>
          Adicionar Evento
          <i className="fas fa-plus" />
        </button>
        {showForm && (
          <div className='form-container'>
            <div className='form'>
              <SelectText
                label="Tipo de Evento"
                name="tipo_evento"
                onChange={onChangeForm}
                options={freightOrder}
                value={eventForm.tipo_evento || ''}
              />
              <TextArea
                value={eventForm.descricao || ''}
                name="descricao"
                label="Descrição"
                placeholder='Descreva o evento.'
                onChange={onChangeForm}
              />
            </div>
            <button
              className='button'
              onClick={handleSubmit}
            >
              Enviar
            </button>
          </div>
        )}
        <StyledListEvents>
          {listEvents ? listEvents.map((el, index) => renderListEvent(el, index)) : (
            <li><b>Nenhum evento encontrado para este frete</b></li>
          )}
        </StyledListEvents>
        
      </div>
    </StyledEvents>
  )
}

AddEvents.propTypes = {
  freightOrder: PropTypes.any.isRequired,
  listEvents: PropTypes.arrayOf(PropTypes.object),
  freightId: PropTypes.string.isRequired,
  feedBackUpdade: PropTypes.func.isRequired
}

AddEvents.defaultProps = {
  listEvents: []
}

export default AddEvents
