import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import Accordeon from '@/views/components/Accordeon'
import { ClientsContainer } from './styles'

const TypeVehicleList = (props) => {
  const {
    onShowItem,
    onEditItem,
    fullList,
    showFullList
  } = props

  const handleShowType = (params) => {
    onShowItem(params.id_tipoveiculo)
  }

  const renderList = (typeVehicle, i) => (
    <Accordeon
      key={i}
      params={typeVehicle}
      showItem={Boolean(showFullList === typeVehicle.id_tipoveiculo)}
      onShowItem={handleShowType}
      onEditItem={onEditItem}
      title="Nome"
      titleValue={typeVehicle.nome}
      subtitle="Quantidade de Placa"
      subtitleValue={typeVehicle.quantidade_placa}
    >
      <>
        <div>
          <span>Nome</span>
          <p className='capitalize'>{typeVehicle.nome || '-'}</p>
        </div>
        <div>
          <span>Quantidade de Placa</span>
          <p>{typeVehicle.quantidade_placa || '-'}</p>
        </div>
        <div>
          <span>Status Ativo</span>
          <ToggleButton
            value={Number(typeVehicle.status) === 1 || false}
            className="styledToggle"
            readOnly
          />
        </div>
      </>
    </Accordeon>
  )

  return (
    <ClientsContainer>
      {fullList.map((typeVehicle, index) => renderList(typeVehicle, index))}
    </ClientsContainer>
  )
}

TypeVehicleList.propTypes = {
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFullList: PropTypes.number
}

TypeVehicleList.defaultProps = {
  showFullList: null
}

export default TypeVehicleList
