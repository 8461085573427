import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 10px;

  & > button {
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    cursor: pointer;
    background-color: transparent;

    &:hover, &.active {
      background-color: var(--color-primary);
      color: var(--color-light);  
    }

    &:disabled {
      background-color: var(--color-gray-light);
      color: #aaa;
      border-color: #aaa;
    }
  }

  @media (min-width: 520px) {
    padding: 10px 20px;
    gap: 20px;
  }
`

export const StyledP = styled.p`
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  color: var(--color-primary);
  font-weight: 600;
  font-size: 14px;

  span {
    color: var(--color-secondary)
  }
`
