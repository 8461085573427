import {
  CLIENTS,
  FREIGHT,
  MATRIZ,
  DRIVER,
  USER,
  MONITOR,
  VEHICLE_TYPE,
  LOAD_TYPE,
} from './parameters'

export const MENU_DEFAULT = [{
    label: 'Frete',
    id: FREIGHT,
    showTop: false,
    icon: 'fas fa-people-carry',
  }, {
    label: 'Clientes',
    id: CLIENTS,
    showTop: false,
    icon: 'fas fa-user-friends',
  }, {
    label: 'Motorista',
    id: DRIVER,
    showTop: false,
    icon: 'fas fa-truck-moving',
  }, {
    label: 'Matriz e Filiais',
    id: MATRIZ,
    showTop: false,
    icon: 'fas fa-building',
  }, {
    label: 'Usuários',
    id: USER,
    showTop: false,
    icon: 'fas fa-user'
  },
  {
    label: 'Tipo de Veículo',
    id: VEHICLE_TYPE,
    showTop: false,
    icon: 'fas fa-truck'
  },
  {
    label: 'Tipo de Carga',
    id: LOAD_TYPE,
    showTop: false,
    icon: 'fas fa-truck-ramp-box'
  },
  {
    label: 'Mapa de Acompanhamento',
    id: MONITOR,
    showTop: true,
    icon: 'fas fa-map'
  },
]

export const CPF_CNPJ = [{
  label: 'Pessoa Física',
  value: 1
}, {
  label: 'Pessoa Jurídica',
  value: 2
}]

export const freightOrder = [{
  label: 'Maior Frete',
  value: 1
}, {
  label: 'Menor Frete',
  value: 2
},{
  label: 'Última Atualização recente',
  value: 3
}, {
  label: 'Última Atualização antiga',
  value: 4
}]

export const freightListFilter = [
  {
    label: 'Em Oferta',
    value: 1,
  },
  {
    label: 'Associado Motorista',
    value: 2,
  },
  {
    label: 'Em Viagem',
    value: 3,
  },
  {
    label: 'Finalizado',
    value: 4,
  },
  {
    label: 'Cancelado',
    value: 6,
  },
];

export const monitorListFilter = [
  {
    label: 'Em Viagem',
    value: 3,
  },
  {
    label: 'Finalizado',
    value: 4,
  },
];

export const freightActions = [
  {
    label: 'Iniciar Frete',
    value: 5,
  },
  {
    label: 'Associar Motorista',
    value: 9,
  },
  {
    label: 'Finalizar Frete',
    value: 6,
  },
  {
    label: 'Cancelar Frete',
    value: 4
  },
  {
    label: 'Mudar Alerta',
    value: 7
  },
  {
    label: 'Gerar relatório',
    value: 8
  },
]
