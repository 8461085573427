import styled from '@emotion/styled'

export const FormContainer = styled.div`
  width: 100%;
  padding: 20px 10px;

  h3 {
    text-align: center;
    color: var(--color-third);
    margin-bottom: 25px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    padding: 13px 0 10px;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    grid-gap: 30px;

    & > div.address {
      grid-column-start: 1;
      grid-column-end: 3;

      label {
        &:first-of-type, &:nth-of-type(3) {
          width: 110px;
        }

        &:nth-of-type(2) {
          width: calc(100% - 260px);
          margin: 0 20px;
        }

        &:nth-of-type(1n+4) {
          width: calc(50% - 10px);
        }

        &:nth-of-type(2n+5) {
          margin-left: 20px;
        }
      }
    }
  }
`

export const StyledBasicData = styled.div`
  width: 100%;
`

export const StyledAddressData = styled.div`
  width: 100%;
`
