import React from 'react'
import PropTypes from 'prop-types'
import { StyledAside } from './styles'

const AsideDefault = ({ children }) => (
  <StyledAside>
    {children}
  </StyledAside>
)

AsideDefault.propTypes = {
  children: PropTypes.any.isRequired
}

export default AsideDefault
