import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import { FormContainer, StyledBasicData } from './styles'
import SelectMultiple from '@/views/fragments/form/SelectMultiple'
import { formatterNumberOnly, formatterPhone, formatterLetterOnly } from '@/utils/formatter'
import { getOptionsUser, getRegisterUser } from '@/constants/requests'
import DefaultModalConfirm from '@/views/fragments/modalTypes/DefaultModalConfirm'

const FreightForm = (props) => {
  const { onChange, formValues, isEdit, isChangePassword, goToDriver } = props

  const [optionList, setOptionList] = useState([])
  const [comparePassword, setComparePassword] = useState(true)
  const [userToShow, setUserToShow] = useState(null)
  const [showConfirmModal, setShowConfirmModal] = useState(null)

  const setupOptions = useCallback(async () => {
    const options = await getOptionsUser()
    setOptionList(options.filiais || [])
  }, [])

  useEffect(() => {
    setupOptions()
  }, [])

  const handleChange = (e, ml) => {
    const { value, name } = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value,
    })
    
    if(formValues.confirm_password == undefined) {
      setComparePassword(true)
    } else if(name == 'confirm_password') {
      if(formValues.password == value) {
        setComparePassword(true)
      } else {
        setComparePassword(false)
      }
    }
  }

  const handleChangeMultiple = (filiais) => {
    const list = filiais.map((el) => {
      const filialList = {
        id_filial: el.value,
      }
      return filialList
    })
    
    onChange({
      ...formValues,
      filiais: list
    })
  }

  const handleChangeToggle = (name, bool) => {
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0,
    })
  }

  const handleBlurLogin = async () => {
    if (!formValues.login) return

    const login = formatterLetterOnly(formValues.login)
    
    const req = await getRegisterUser(login)

    const match = req.usuarios.find((el) => el.login === formValues.login)

    if (match && req.status.active) {
      setUserToShow(req.usuarios[0])
      setShowConfirmModal(true)
      return
    }
  }

  const renderConfirmModal = () => (
    <DefaultModalConfirm
      msg={`Usuário com nome ${userToShow.login} já está cadastrado, deseja visualizar o usuário?`}
      closeModal={() => setShowConfirmModal(false)}
      callBack={goToRegisteredUser}
    />
  )
  
  const goToRegisteredUser = () => {
    goToDriver(userToShow)
    setShowConfirmModal(false)
  }

  return (
    <>
      <FormContainer>
        {showConfirmModal && renderConfirmModal()}
        <StyledBasicData>
          {!isChangePassword && (
            <div>
              <h3>Cadastro de Usuário</h3>
              <InputText
                label='Nome'
                value={formValues.nome || ''}
                name='nome'
                onChange={handleChange}
              />
              <InputText
                value={formValues.login || ''}
                name='login'
                onChange={handleChange}
                label='Login'
                isDisabled={isEdit}
                onBlur={() => handleBlurLogin()}
              />
              {!isEdit && (
                <InputText
                  label='Senha'
                  value={formValues.password || ''}
                  name='password'
                  type='password'
                  onChange={handleChange}
                />
              )}
              {!isEdit && (
                <InputText
                  label='Confirmar a senha'
                  value={formValues.confirm_password || ''}
                  name='confirm_password'
                  type='password'
                  onChange={handleChange}
                />
              )}
              {!comparePassword && !isEdit && (
                <div className='red'>Senhas não coincidem</div>
              )}
              <InputText
                label='Email'
                name='email'
                onChange={handleChange}
                type='email'
                value={formValues.email || ''}
              />
              <InputText
                label='Telefone'
                name='telefone'
                onChange={handleChange}
                value={formatterPhone(formValues.telefone) || ''}
              />
              <SelectMultiple
                label='Filiais'
                onChange={handleChangeMultiple}
                options={optionList}
                values={formValues.filiais || []}
              />
              <div>
                <ToggleButton
                  value={Number(formValues.status) === 1 || false}
                  name='status'
                  label='Status'
                  className='styledToggle'
                  onClick={handleChangeToggle}
                />
              </div>
            </div>
          )}
          
          {isEdit && (
            <div>
              {( !isChangePassword ) && (
                <ToggleButton
                  value={Number(formValues?.change_password) === 1 || false}
                  name='change_password'
                  label='Deseja mudar a senha'
                  className='styledToggle'
                  onClick={handleChangeToggle}
                />
              )}
              {(Number(formValues?.change_password) === 1 || isChangePassword) && (
                <InputText
                  label='Senha'
                  value={formValues.password || ''}
                  name='password'
                  type='password'
                  onChange={handleChange}
                />
              )}
              {(Number(formValues?.change_password) === 1 || isChangePassword) && (
                <InputText
                  label='Confirmar a senha'
                  value={formValues.confirm_password || ''}
                  name='confirm_password'
                  type='password'
                  onChange={handleChange}
                />
              )}
              {!comparePassword && (
                <div className='red'>Senhas não coincidem</div>
              )}
            </div>
          )}
        </StyledBasicData>
      </FormContainer>
    </>
  )
}

FreightForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goToDriver: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool,
  isChangePassword: PropTypes.bool
}

FreightForm.defaultProps = {
  isEdit: false,
  goToDriver: null,
  isChangePassword: false
}

export default FreightForm
