export const getAllParams = () => {
  const auth = window.localStorage.getItem('authentication')
  const user = window.localStorage.getItem('user')
  const profile = window.localStorage.getItem('profile')
  const novo_usuario = window.localStorage.getItem('novo_usuario')

  return {
    auth,
    user,
    profile,
    novo_usuario,
  }
}

export const deleteParams = () => {
  window.localStorage.removeItem('authentication')
  window.localStorage.removeItem('user')
  window.localStorage.removeItem('profile')
  window.localStorage.removeItem('novo_usuario')
}

export const setParams = (name, value) => {
  window.localStorage.setItem(name, value)
}
