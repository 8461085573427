import React from 'react'
import PropTypes from 'prop-types'
import Accordeon from '@/views/components/Accordeon'
import { ClientsContainer } from './styles'
import { formatterCPForCNPJ } from '@/utils/formatter'

const ParentList = (props) => {
  const {
    onShowParent,
    onEditParent,
    fullList,
    showItem
  } = props

  const handleShowClient = (params) => {
    onShowParent(params.id)
  }

  const renderList = (parent, i) => (
    <Accordeon
      key={i}
      params={parent}
      showItem={Boolean(showItem === parent.id)}
      onShowItem={handleShowClient}
      onEditItem={onEditParent}
      title="CNPJ"
      titleValue={formatterCPForCNPJ(parent.doc_number)}
      subtitle="Nome Fantasia"
      subtitleValue={parent.fantasia_sigla}
    >
      <>
        <div>
          <span>CNPJ</span>
          <p>{formatterCPForCNPJ(parent.doc_number)}</p>
        </div>
        <div>
          <span>Incrição Estadual</span>
          <p>{parent.inscricao_estadual || '-'}</p>
        </div>
        <div>
          <span>Nome Fantasia</span>
          <p>{parent.nome_fantasia || '-'}</p>
        </div>
        <div>
          <span>Razão Social</span>
          <p>{parent.razao_social || '-'}</p>
        </div>
        <div>
          <span>Sigla</span>
          <p>{parent.sigla || '-'}</p>
        </div>
        <div>
          <span>Email</span>
          <p>{parent.email || '-'}</p>
        </div>
        <div>
          <span>Telefone</span>
          <p>{parent.telefone || '-'}</p>
        </div>
        <div className='address'>
          <h4>Endereço</h4>
        </div>
        <div>
          <span>CEP</span>
          <p>{parent.endereco.cep || '-'}</p>
        </div>
        <div>
          <span>Logradouro</span>
          <p>{parent.endereco.logradouro || '-'}</p>
        </div>
        <div>
          <span>numero</span>
          <p>{parent.endereco.numero || '-'}</p>
        </div>
        <div>
          <span>bairro</span>
          <p>{parent.endereco.bairro || '-'}</p>
        </div>
        <div>
          <span>cidade</span>
          <p>{parent.endereco.cidade || '-'}</p>
        </div>
        <div>
          <span>estado</span>
          <p>{parent.endereco.uf || '-'}</p>
        </div>
        <div>
          <span>complemento</span>
          <p>{parent.endereco.complemento || '-'}</p>
        </div>
      </>
    </Accordeon>
  )

  return (
    <ClientsContainer>
      {fullList.map((parent, index) => renderList(parent, index))}
    </ClientsContainer>
  )
}

ParentList.propTypes = {
  onShowParent: PropTypes.func.isRequired,
  onEditParent: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showItem: PropTypes.number
}

ParentList.defaultProps = {
  showItem: null
}

export default ParentList
