import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import ClientForm from '@/views/components/forms/ClientForm'
import ClientList from '@/views/components/forms/ClientList'
import {
  getAllClients,
  getRegisterClient,
  postRegisterClient,
  putRegisterClient
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import { formatterCPForCNPJ, formatterNumberOnly } from '@/utils/formatter'
import { StyledBodyContainer } from './styles'
import Loading from '../components/Loading/Loading'

const DEFAULT_FORM = {
  tipo: 1,
  active: 1
}

const Clients = (props) => {
  const { isMobile } = props

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [clientsList, setClientsList] = useState([])
  const [showFullList, setShowFullList] = useState(null)
  const [clientToEdit, setClientToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const getFullList = useCallback(async () => {
    const list = await getAllClients()
    setClientsList(list.clientes || [])
  }, [])

  useEffect(() => {
    getFullList()
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }

  const handleEditChange = (form) => {
    setClientToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues,
      doc_number: formatterNumberOnly(formValues.doc_number),
      rg: formatterNumberOnly(formValues.rg) || null,
      telefone: formatterNumberOnly(formValues.telefone) || null,
      inscricao_estadual: formatterNumberOnly(formValues.inscricao_estadual) || null,
      endereco: {
        ...formValues.endereco,
        cep: formatterNumberOnly(formValues.endereco?.cep),
      }
    }
    return form
  }

  const submitForm = async () => {
    setLoading(true)
    const form = setupForm()
    const req = await postRegisterClient(form)
    setLoading(false)
    if (req.status.active) {
      setShowModal('sucess')
      setShowType('list-clients')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }
  }

  const submitEditForm = async () => {
    const req = await putRegisterClient(clientToEdit)

    const index = clientsList.findIndex(
      (elem) => elem.id_cliente === clientToEdit.id_cliente
    )

    const newForm = [...clientsList]

    newForm[index] = clientToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setClientsList(newForm)
      setShowFullList(clientToEdit.id_cliente)
      handleSearch()
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onShowClient = (e) => {
    setShowFullList(e === showFullList ? null : e)
  }

  const onEditClient = (e) => {
    setClientToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setClientToEdit({})
  }

  const onShowConfirmModal = (client) => {
    setClientToEdit(client)
    setShowEditModal(true)
    setFormValues(DEFAULT_FORM)
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <ClientForm
              onChange={handleChange}
              formValues={formValues}
              goToClient={onShowConfirmModal}
            />
            <button className='button formButton' onClick={submitForm}>Enviar</button>
          </>
        )
      case 'list-clients':
        return (
          <ClientList
            onShowItem={onShowClient}
            onEditItem={onEditClient}
            fullList={clientsList}
            showFullList={showFullList}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Cliente"
      size='lg'
    >
      <ClientForm
        onChange={handleEditChange}
        formValues={clientToEdit}
        isEdit
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const req = await getRegisterClient(formatterNumberOnly(searchBy))

    setLoading(false)
    if (!req) {
      return setShowType(null)
    }

    setShowType('list-clients')
    setShowFullList(req.clientes.id_cliente)
    setClientsList(req.clientes)
  }

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showEditModal && renderEditModal()}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={formatterCPForCNPJ(searchBy)}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Clientes"
              placeholder="CPF/CNPJ"
              onSubmitSearch={handleSearch}
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

Clients.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default Clients
