import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import Accordeon from '@/views/components/Accordeon'
import { ClientsContainer } from './styles'
import { formatterCPF } from '@/utils/formatter'

const DriversList = (props) => {
  const {
    onShowItem,
    onEditItem,
    fullList,
    showFullList
  } = props

  const handleShowClient = (params) => {
    onShowItem(params.id_motorista)
  }

  const renderList = (driver, i) => (
    <Accordeon
      key={i}
      params={driver}
      showItem={Boolean(showFullList === driver.id_motorista)}
      onShowItem={handleShowClient}
      onEditItem={onEditItem}
      title="CPF"
      titleValue={formatterCPF(driver.cpf)}
      subtitle="Nome"
      subtitleValue={driver.nome}
    >
      <>
        <div>
          <span>Nome</span>
          <p className='capitalize'>{driver.nome || '-'}</p>
        </div>
        <div>
          <span>CPF</span>
          <p>{driver.cpf || '-'}</p>
        </div>
        <div>
          <span>RG</span>
          <p>{driver.rg || '-'}</p>
        </div>
        <div>
          <span>Data de Nascimento</span>
          <p>{driver.data_nascimento || '-'}</p>
        </div>
        <div>
          <span>Email</span>
          <p>{driver.email || '-'}</p>
        </div>
        <div>
          <span>Telefone</span>
          <p>{driver.telefone || '-'}</p>
        </div>
        <div>
          <span>Número da CNH</span>
          <p>{driver.numero_cnh || '-'}</p>
        </div>
        <div>
          <span>Nome da Mãe</span>
          <p className='capitalize'>{driver.mae || '-'}</p>
        </div>
        <div>
          <span>Nome da Pai</span>
          <p className='capitalize'>{driver.pai || '-'}</p>
        </div>
        <div>
          <span>Categoria da CNH</span>
          <p>{driver.categoria_cnh || '-'}</p>
        </div>
        <div>
          <span>Emissão da CNH</span>
          <p>{driver.emissao_cnh || '-'}</p>
        </div>
        <div>
          <span>Validade da CNH</span>
          <p>{driver.validade_cnh || '-'}</p>
        </div>
        <div>
          <span>Estado da CNH</span>
          <p>{driver.estado_cnh_texto || '-'}</p>
        </div>
        <div className='address'>
          <h4>Endereço</h4>
        </div>
        <div>
          <span>CEP</span>
          <p>{driver.endereco.cep || '-'}</p>
        </div>
        <div>
          <span>Logradouro</span>
          <p>{driver.endereco.logradouro || '-'}</p>
        </div>
        <div>
          <span>numero</span>
          <p>{driver.endereco.numero || '-'}</p>
        </div>
        <div>
          <span>bairro</span>
          <p>{driver.endereco.bairro || '-'}</p>
        </div>
        <div>
          <span>cidade</span>
          <p>{driver.endereco.cidade || '-'}</p>
        </div>
        <div>
          <span>estado</span>
          <p>{driver.endereco.uf || '-'}</p>
        </div>
        <div>
          <span>complemento</span>
          <p>{driver.endereco.complemento || '-'}</p>
        </div>
        <div>
          <span>Status Ativo</span>
          <ToggleButton
            value={Number(driver.status) === 1 || false}
            className="styledToggle"
            readOnly
          />
        </div>
      </>
    </Accordeon>
  )

  return (
    <ClientsContainer>
      {fullList.map((driver, index) => renderList(driver, index))}
    </ClientsContainer>
  )
}

DriversList.propTypes = {
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFullList: PropTypes.number
}

DriversList.defaultProps = {
  showFullList: null
}

export default DriversList
