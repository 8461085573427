import styled from '@emotion/styled';

export const ElementContent = styled.div`
  width: 100%;

  margin: 20px 0;

  .maps {
    height: 350px;
    max-height: 350px;
    width: 100%;
  }

  #map_canvas {
    height: 350px;
    max-height: 350px;
    width: 100%;
  }

  .wrap {
    padding: 25px 0;
    width: 100%;
    margin: 0 auto;
  }
`;
