const pathIbge = 'https://servicodados.ibge.gov.br/api/v1/localidades'
const fipe = 'https://parallelum.com.br/fipe/api/v2/cars/brands'
export const defaultPath = process.env.REACT_APP_SERVER_API

export const IMAGES_PATH = `${process.env.REACT_APP_SERVER_API}/imgMacros`

export const API = {
  POST_LOGIN_ACCESS: `${defaultPath}/wsValidaUsuario.rule?sys=RDC`,
  GET_BY_POSTAL_CODE: `https://viacep.com.br/ws/:cep/json/`,
  GET_CNPJ_INFO: 'https://www.receitaws.com.br/v1/cnpj',
  GET_USER_IP: 'https://ipinfo.io/json',
  GET_COUNTRY_LIST: `${pathIbge}/paises?orderBy=nome`,
  GET_STATES_LIST: `${pathIbge}/estados`,
  GET_CITY_LIST: `${pathIbge}/estados/:uf/municipios`,
  GET_CAR_LIST: `${fipe}`,
  GET_MODEL_CAR_LIST: `${fipe}/:brand/models`,
  GET_YEAR_CAR_BY_MODEL: `${fipe}/:brand/models/:model/years`,
  POST_REGISTER_CLIENT: `${defaultPath}/wsCadastrarCliente.rule?sys=RDC`,
  GET_ALL_CLIENTS: `${defaultPath}/wsConsultarCliente.rule?sys=RDC`,
  PUT_REGISTER_CLIENT: `${defaultPath}/wsAtualizarCliente.rule?sys=RDC`,
  GET_REGISTER_CLIENT: `${defaultPath}/wsConsultarDocumentoCliente.rule?sys=RDC`,
  GET_CNPJ_CONSULT: `${defaultPath}/wsConsultarCnpj.rule?sys=RDC`,
  GET_CEP_CONSULT: `${defaultPath}/wsConsultarCep.rule?sys=RDC`,
  MATRIZ_API: `${defaultPath}/wsMatrizFiliais.rule?sys=RDC`,
  DRIVERS_API: `${defaultPath}/wsMotorista.rule?sys=RDC`,
  TYPE_VEHICLE_API: `${defaultPath}/wsTipoVeiculo.rule?sys=RDC`,
  TYPE_LOAD_API: `${defaultPath}/wsTipoCarga.rule?sys=RDC`,
  FREIGHT_API: `${defaultPath}/wsFrete.rule?sys=RDC`,
  FREIGHT_OPTION_API: `${defaultPath}/wsOptionsFrete.rule?sys=RDC`,
  GET_CITY_CONSULT: `${defaultPath}/wsConsultarCidades.rule?sys=RDC`,
  GET_CITY_BY_UF_CONSULT: `${defaultPath}/wsConsultarCidadesUf.rule?sys=RDC`,
  USER_API: `${defaultPath}/wsUsuario.rule?sys=RDC`,
  CONSULT_DRIVER_API: `${defaultPath}/wsConsultarCpfMotorista.rule?sys=RDC`,
  OPTIONS_USER_REGISTER: `${defaultPath}/wsOptionsUsuario.rule?sys=RDC`,
  CONSULT_DRIVER_FREIGHT: `${defaultPath}/wsConsultarFreteMotoristas.rule?sys=RDC`,
  PUT_FOREIGN_DRIVER_FREIGHT: `${defaultPath}/wsAtualizarMotoristaFrete.rule?sys=RDC`,
  GET_CPF_VALID: `${defaultPath}/wsValidarCpf.rule?sys=RDC`,
  PUT_CHANGE_PASSWORD: `${defaultPath}/wsAlterarSenhaUsuario.rule?sys=RDC`,
  GET_REGISTER_USER: `${defaultPath}/wsConsultarLoginUsuario.rule?sys=RDC`,
  DRIVER_OPTION_API: `${defaultPath}/wsOptionsMotorista.rule?sys=RDC`,
  MAP_POINTS_API: `${defaultPath}/wsConsultarPontosMapa.rule?sys=RDC`,
  FREIGHT_MAP_POINTS_API: `${defaultPath}/wsMapaFrete.rule?sys=RDC`,
  GET_QUALITY_IMAGE: `${defaultPath}/wsConsultarImagemQualidadeFrete.rule?sys=RDC`,
  EVENT_OPTIONS: `${defaultPath}/wsOptionsEvento.rule?sys=RDC`,
  EVENT_REQUESTS: `${defaultPath}/wsEvento.rule?sys=RDC`,
  FINALIZE_FREIGHT: `${defaultPath}/wsFinalizarFrete.rule?sys=RDC`,
  CANCEL_FREIGHT: `${defaultPath}/wsCancelarFrete.rule?sys=RDC`,
  INITIALIZE_FREIGHT: `${defaultPath}/wsIniciarViagem.rule?sys=RDC`,
  ALERT_FREIGHT: `${defaultPath}/wsAlertaViagem.rule?sys=RDC`,
  PARENT_DOCUMENT: `${defaultPath}/wsConsultarDocumentoMatriz.rule?sys=RDC`,
  REPORT_FREIGHT: `${defaultPath}/wsGerarRelatorio.rule?sys=RDC`,
  VALIDATE_FREIGHT: `${defaultPath}/wsValidacaoFreteMotorista.rule?sys=RDC`,
  OFFER_CONSULT_FREIGHT: `${defaultPath}/wsConsultaOfertaFrete.rule?sys=RDC`,
}

 