import React from 'react'
import PropTypes from 'prop-types'
import { StyledTemplate } from './styles'

const TemplateContainer = ({ children }) => (
  <StyledTemplate>
    {children}
  </StyledTemplate>
)

TemplateContainer.propTypes = {
  children: PropTypes.any.isRequired
}

export default TemplateContainer
