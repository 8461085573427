import React from 'react'
import PropTypes from 'prop-types'
import { LoadEmptyStyled } from './styles'

const LoadEmptyPage = ({ isMobile }) => (
  <LoadEmptyStyled>
    <h2>
      <span><i className="fas fa-hand-point-left" /></span>
      <i className="far fa-grin" />
    </h2>
    <p>
      {`Aqui não há nada para exibir. Quer fazer uma busca no menu ${isMobile ? 'a cima' : 'ao lado'}? Busque tudo clicando na lupa ou digite o campo específico informado para poder buscar!`}
    </p>
  </LoadEmptyStyled>
)

LoadEmptyPage.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default LoadEmptyPage
