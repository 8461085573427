import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@/views/fragments/Modal'
import { CustomModal } from './styles'

const DefaultModalConfirm = (props) => {
  const {
    closeModal,
    msg,
    callBack,
  } = props

  return (
    <Modal closeModal={closeModal}>
      <CustomModal>
        <h4>{msg}</h4>
        <div className='buttons-modal'>
          <button className='button' onClick={closeModal}>
            Não
          </button>
          <button className='button' onClick={callBack}>
            Sim
          </button>
        </div>
      </CustomModal>
    </Modal>
  )
}

DefaultModalConfirm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired
}

export default DefaultModalConfirm
