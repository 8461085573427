import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomLoading, StyledHint } from './styles'
import Loading from '@/views/components/Loading/Loading'

const SelectText = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    options,
    isRequired,
    notEmptyOption,
    isDisabled,
    className,
    isLoading,
    isError,
    isSuccess,
    hint,
  } = props

  const renderOptions = (item, index) => (
    <option key={index} value={item.value}>{item.label}</option>
  )

  const renderType = () => {
    if (isSuccess) return 'success'
    if (isError) return 'alert'
    return ''
  }

  return (
    <Field className={className}>
      <span>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={renderType()}
        required={isRequired}
        disabled={isDisabled || isLoading}
      >
        {!notEmptyOption && (<option value="-1">Selecione uma opção</option>)}
        {options.map((item, index) => renderOptions(item, index))}
      </select>
      {isLoading && (
        <CustomLoading>
          <Loading noBackground small />
        </CustomLoading>
      )}
      {(isError && !isSuccess) && (
        <StyledHint className={isSuccess ? 'success' : ''}>
          {hint}
        </StyledHint>
      )}
    </Field>
  )
}

SelectText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isRequired: PropTypes.bool,
  notEmptyOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  hint: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

SelectText.defaultProps = {
  isRequired: false,
  notEmptyOption: false,
  isDisabled: false,
  className: '',
  isLoading: false,
  hint: '',
  isError: false,
  isSuccess: false,
}

export default SelectText
