export const FREIGHT = 'freight'
export const ADMIN_PROFILE = 'gerente'
export const USER_PROFILE = 'usuario'
export const LOGIN = 'login'
export const FORBIDDEN = 'forbidden'
export const RELOGIN = 'relogin'
export const REQUIRED_FIELD = 'Preenchimento obrigatório!'
export const CLIENTS = 'clients'
export const MATRIZ = 'matriz-e-filiais'
export const DRIVER = 'drivers'
export const USER = 'cadastro-usuario'
export const MONITOR = 'monitor-fretes'
export const VEHICLE_TYPE = 'tipo-veiculo'
export const LOAD_TYPE = 'tipo-carga'