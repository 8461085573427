import React from 'react'
import PropTypes from 'prop-types'
import { AccordeonContent } from './styles'

const Accordeon = (props) => {
  const {
    onShowItem,
    onEditItem,
    showItem,
    children,
    title,
    titleValue,
    subtitle,
    subtitleValue,
    params,
  } = props

  return (
    <AccordeonContent
      className={showItem ? 'active' : ''}
    >
      <div onClick={() => onShowItem(params)} className="clickArea">
        <div>
          <span>{title}</span>
          <p>{titleValue}</p>
        </div>
        {showItem ? children : (
          <div>
            <span>{subtitle}</span>
            <p>{subtitleValue}</p>
          </div>
        )}
      </div>
      <div className='buttons'>
        <button
          className='button editar'
          onClick={() => onEditItem(params)}
        >
          <i className="fas fa-pencil-alt" />
        </button>
      </div>
    </AccordeonContent>
  )
}

Accordeon.propTypes = {
  children: PropTypes.any.isRequired,
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  params: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any
  ]).isRequired,
  showItem: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleValue: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitleValue: PropTypes.string.isRequired
}

Accordeon.defaultProps = {
  showItem: null
}

export default Accordeon
