import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import { format } from 'date-fns'
import { getEventOptions, getEventsList, getLastMapPoints, getMapPoints } from '@/constants/requests'
import MapsList from '../components/forms/MapsList'
import Loading from '@/views/components/Loading/Loading'
import { getFreightImage } from '@/constants/requests'
import { freightOrder, monitorListFilter } from '@/constants/optionsValues'
import SelectText from '../fragments/form/SelectText'
import AddEvents from '../components/forms/AddEvents'
import {
  StyledBodyContainer,
  StyledImage,
  ListElement,
  StyledImageModal,
  StyledModal,
  StyledContentGeo,
  StyledBreadCrumb,
  StyledEventVisualizationContainer,
  StyledModalImage
} from './styles'
import Pagination from '../fragments/Pagination'

const defaultPagination = {
  pagina: 1,
  total_por_pagina: 100
}

const Monitors = () => {
  const [sortValue, setSortValue] = useState('')
  const [filterValue, setFilterValue] = useState(-1)
  const [freightId, setFreightId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showEvents, setShowEvents] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [freightList, setFreightList] = useState([])
  const [freightEndpoint, setFreightEndpoint] = useState({}  )
  const [uniqueFreightList, setUniqueFreightList] = useState([])
  const [showImageModal, setShowImageModal] = useState(false)
  const [imageModal, setImageModal] = useState(null)
  const [macroImageModal, setMacroImageModal] = useState(null)
  const [macroNumber, setMacroNumber] = useState(null)
  const [mapConfig, setMapConfig] = useState(null)
  const [shouldUpdate, setShouldUpdate] = useState(true)
  const [eventsOptions, setEventOptions] = useState([])
  const [listEvents, setListEvents] = useState(null)
  const [pagination, setPagination] = useState(defaultPagination)

  const handleLastFreight = useCallback(async (page, size, filter) => {
    setLoading(true)
    const endpoint = await getLastMapPoints(page, size, filter)
    
    if(endpoint?.mapafretes?.length === 0) {
      setFreightList(endpoint.mapafretes)
      setMapConfig({ endpoint, freight: false })
      setLoading(false)
      return;
    }
    setFreightList(endpoint.mapafretes)
    setFreightEndpoint(endpoint)
    setFreightId(null)
    setPagination({
      pagina: endpoint.paginacao.pagina,
      total_de_paginas: endpoint.paginacao.tamanho_pagina,
      total_de_registros: endpoint.paginacao.itens,
      total_por_pagina: endpoint.paginacao.tamanho
    })
    setMapConfig({ endpoint, freight: false })
    setLoading(false)
    setShouldUpdate(true)
  }, [])

  const handleFreight = async (frete) => {
    setLoading(true)
    const endpoint = await getMapPoints(frete)
    const newObject = endpoint.mapafretes.filter(
      (x) => x.macro !== 'Coleta Automatica'
    )

    setUniqueFreightList(newObject)
    setFreightId(endpoint.mapafretes[0].frete)
    setMapConfig({ endpoint, freight: true })
    setShouldUpdate(true)
    setLoading(false)
  }

  const freightIdRef = React.useRef();

  useEffect(() => {
    freightIdRef.current = freightId;
  }, [freightId]);

  const setupOptionsEvent = useCallback(async () => {
    const options = await getEventOptions()
    setEventOptions(options?.tipo_evento || [])
  }, [])

  useEffect(() => {
    handleLastFreight(defaultPagination.pagina, defaultPagination.total_por_pagina, filterValue)
    setupOptionsEvent()
    let v = setInterval(() => {
      if(freightIdRef.current === null) {
        handleLastFreight(pagination.pagina, pagination.total_por_pagina, filterValue)
      } else {
        return () => clearInterval(v)
      }
    }, 600000)
    return () => clearInterval(v);
  }, [ handleLastFreight ])

  const onCloseModal = () => {
    setShowModal(null)
    setPagination()
  }

  const handleImageModal = async (value) => {
    setShowImageModal(true)
    setMacroNumber(value.controle + 1)
    const endpoint = await getFreightImage(value.id)
    setImageModal(endpoint.imagem[0].foto)
    setMacroImageModal(value.macro)
  }

  const renderImageModal = () => (
    <Modal
      closeModal={onCloseImageModal}
      title={'Imagem da macro - ' + macroImageModal}
      size='md'
      customClass='first-of-all'
    >
      <StyledModal>
        <h5 className='dados'>Frete de número: {freightId}</h5><br></br>
        <h5 className='dados'>Macro de número: {macroNumber}</h5>
        <div className='imagem-modal'>
          <StyledImageModal>
            <img
              className='img-modal'
              src={`data:image/jpeg;base64,${imageModal}`}
              alt={`data:image/jpeg;base64,${imageModal}`}
            />
          </StyledImageModal>
        </div>
      </StyledModal>
    </Modal>
  )

  const onCloseEditModal = () => {
    setShowEditModal(false)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title='Mapa de Fretes'
      size='lg'
    ></Modal>
  )

  const convertTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = String(totalMinutes % 60).padStart(2, "0");

    return (
      <>
        {hours}:{minutes}
      </>
    )
  }

  const convertMinutes = (totalMinutes) => {
    var a = totalMinutes.split(':'); // split it at the colons
    var minutes = (+a[0]) * 60 + (+a[1]);
    return minutes
  }
  
  const sliceText = (text) => {
    if (!text) return
    if (text.length <= 75) return text

    return text.substring(0, 75) + '...'
  }
  
  const verifyIfNullOrEmpty = (value) => {
    if(value === null || value === undefined || value === "") {
      return true
    } else {
      return false
    }
  }

  const renderMobileList = (item, index) => (
    <div
      className='row freight'
      key={index}
    >
      <div onClick={() => handleFreight(item.frete)}>
        <span>Frete</span>
        <p><b>{item.frete || '-'}</b></p>
        <div>{item.sigla || '-'}</div>
      </div>
      <div className='inset'>
        <div>
          <div>
            <span>Cliente</span>
            <p title={item.cliente}>{item.cliente || '-'}</p>
          </div>
          <div>
            <span>Motorista</span>
            <p>{item.motorista || '-'}</p>
          </div>
        </div>
        <div>
          <div>
            <span>Origem</span>
            <p>{item.origem_cidade_texto || '-'}</p>
          </div>
          <div>
            <span>Destino</span>
            <p>{item.destino_cidade_texto || '-'}</p>
          </div>
        </div>
        <div>
          <div title={item.mensagem_livre}>
            <span>Ultima Macro</span>
            <p>{item.ultima_macro || '-'}</p>
          </div>
          <div>
            <span>Última Posição</span>
            {item?.data_recebimento === null ? (
              <p>-</p>
            ): (
              <p>{format(new Date(item?.data_recebimento), 'dd/MM/yy HH:mm') || '-'}</p>
            )}
          </div>
        </div>
        <div className="event-msg">
          <div title={item.descricao_evento}>
            <span>Último Evento</span>
            <p className="noWhiteSpace">{sliceText(item?.descricao_evento) || '-'}</p>
          </div>
          <div title={item.descricao_evento}>
            <span>Placas</span>
              <p className="noWhiteSpace">{item.placas || '-'}</p>        
          </div>
        </div>
        <div className='grid-frete'>  
          {item?.macro === 'Inicio Manual' ? (
            <i className="far fa-hand"  title='Iniciado manualmente'/>
          ) : null}

          {!verifyIfNullOrEmpty(item?.motorista_telefone) ? (    
            <a href={`https://web.whatsapp.com/send?phone=${item.motorista_telefone}`} target="_blank" rel="noreferrer">
              <span className="font-phone"><i className="fa-brands fa-whatsapp"  title={item.motorista_telefone}/></span> 
            </a>      
          ) : null}
        </div>
      </div>
      <div onClick={() => handleFreight(item.frete)}>
        <span>Atual. Macro <i className="fas fa-info-circle" title="Última atualização dos horários das macros entre cliente e servidor em Horas:Minutos."/></span>
        <p><b>{convertTime(item.ultima_posicao_minutos) || '-'}</b></p>
        <hr></hr>

        {item?.alerta === 1 ? (

          <a>
            {(Number(item?.ultima_posicao_evento) < Number(convertMinutes(item?.tempo1))) ? (
              <span style={{color: "var(--color-gray-dark)"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            ) : Number(item?.ultima_posicao_evento) >= Number(convertMinutes(item?.tempo1)) && Number(item?.ultima_posicao_evento) < Number(convertMinutes(item?.tempo2)) ?(
              <span style={{color: "yellow"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            ) : (
              <span style={{color: "red"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            )}
            {(Number(item?.ultima_posicao_evento) < Number(convertMinutes(item?.tempo1))) ? (
              <b style={{color: "var(--color-gray-dark)"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            ) : Number(item?.ultima_posicao_evento) >= Number(convertMinutes(item?.tempo1)) && Number(item?.ultima_posicao_evento) < Number(convertMinutes(item?.tempo2)) ?(
              <b style={{color: "yellow"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            ) : (
              <b style={{color: "red"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            )}
          </a>

        ) : (
  
          <a>
            {(Number(item?.ultima_posicao_evento) < 240) ? (
              <span style={{color: "var(--color-gray-dark)"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            ) : Number(item?.ultima_posicao_evento) >= 240 && Number(item?.ultima_posicao_evento) < 300 ? (
              <span style={{color: "yellow"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            ) : (
              <span style={{color: "red"}}>Atual. Evento <i className="fas fa-info-circle" title="Última atualização entre os horários do evento."/></span>
            )}
            {(Number(item?.ultima_posicao_evento) < 240) ? (
              <b style={{color: "var(--color-gray-dark)"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            ) : Number(item?.ultima_posicao_evento) >= 240 && Number(item?.ultima_posicao_evento) < 300 ? (
              <b style={{color: "yellow"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            ) : (
              <b style={{color: "red"}}>{convertTime(item.ultima_posicao_evento) || '-'}</b>
            )}
          </a>

        )}

        <div>{item.status_frete}</div>
      </div>
    </div>
  )

  const renderMobileMacroList = (item, index) => (
    <div className='row' key={index}>
      <div>
        <span>#</span>
        <p><b>{item.controle + 1 || '-'}</b></p>
      </div>
      <div className='inset'>
        <div className='name'>
          <span>Macro</span>
          <p>{item.macro || '-'}</p>
        </div>
        <div className='name'>
          <span>Mensagem Livre</span>
          <p>{item.mensagem_livre || '-'}</p>
        </div>
        <div className='name'>
          <span>Latitude</span>
          <p>{item.lat || '-'}</p>
        </div>
        <div className='name'>
          <span>Longitude</span>
          <p>{item.lng || '-'}</p>
        </div>
        <div>
          <span>Data</span>
          <p>{item.data_recebimento || '-'}</p>
        </div>
      </div>
      {(item.foto !== 'nulo') && (item.foto !== null) && (item.foto !== 'bnVsbw==') && (item.foto !== "") ? (
        <div className='img-handle' onClick={() => handleImageModal(item)}>
          <StyledImage>
            <img
              className='img-list'
              src={`data:image/jpeg;base64,${item.foto}`}
              alt={`data:image/jpeg;base64,${item.foto}`}
            />
          </StyledImage>
        </div>
      ) : (
        <div>Sem imagem</div>
      )}
    </div>
  )

  const onCloseImageModal = () => {
    setShowImageModal(false)
    setImageModal(false)
    setMacroImageModal(null)
    setMacroNumber(null)
  }

  const smallestSort = (name) => {
    const list = [...freightList]
    const sortList = list.sort((a, b) => {
      if (Number(a[name]) > Number(b[name])) {
        return 1
      }

      if (Number(a[name]) < Number(b[name])) {
        return -1
      }

      return 0
    })

    setFreightList(sortList)
  }
  const shortSort = (name) => {
    const list = [...freightList]
    const sortList = list.sort((a, b) => {
      if (Number(a[name]) < Number(b[name])) {
        return 1
      }

      if (Number(a[name]) > Number(b[name])) {
        return -1
      }

      return 0
    })

    setFreightList(sortList)
  }

  const onChangeSort = (e) => {
    const { value } = e.target
    if (Number(value) === 1) {
      shortSort('frete')
    }

    if (Number(value) === 2) {
      smallestSort('frete')
    }

    if (Number(value) === 3) {
      smallestSort('ultima_posicao_minutos')
    }

    if (Number(value) === 4) {
      shortSort('ultima_posicao_minutos')
    }

    setSortValue(value)
  }

  const onChangeFilter = async (e) => {
    const { value } = e.target
    await setFilterValue(value)
    handleLastFreight(pagination.pagina, pagination.total_por_pagina, value)
  }

  const handleUpdate = (bool) => {
    setShouldUpdate(bool)
  }

  const setupList = async () => {
    const list = await getEventsList(freightId)

    let newFreightList = freightList;
    for (let i = 0; i < newFreightList.length; i++) {
      const element = newFreightList[i];
      if(Number(element.frete) === Number(freightId)) {
        newFreightList[i].data_evento = list.eventos[0].data
        newFreightList[i].descricao_evento = list.eventos[0].descricao
      }
    }
    setFreightList(newFreightList)

    setListEvents(list.eventos)
  }

  const handleShowEvents = async () => {
    if (!showEvents) {
      setupList()
    }
    setShowEvents(!showEvents)
  }

  const onCloseMacroModal = async () => {
    setLoading(true)
    setFreightId(null)
    const endpoint = freightEndpoint
    await setMapConfig({ endpoint, freight: false })
    setLoading(false)
    setShouldUpdate(true)
    setShowEvents(false)
  }

  const feedBackUpdade = async () => {
    setupList()
  }

  const changePagination = (page) => {
    handleLastFreight(page, pagination.total_por_pagina, filterValue)
  }

  return (
    <section className='section'>
      {loading && <Loading msg='Aguarde um instante...' />}
      <StyledModalImage>
        {showImageModal && renderImageModal()}
      </StyledModalImage>
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg='Cadastrado com sucesso!'
        />
      )}
      {showEditModal && renderEditModal()}
      <StyledContentGeo>
        {freightId ? (
          <Modal
            closeModal={onCloseMacroModal}
            size="xl"
            customClass={freightId ? 'custom-modal active' : 'custom-modal'}
          >
            <MapsList
              mapConfig={mapConfig}
              handleImageModal={handleImageModal}
              handleUpdate={handleUpdate}
              shouldUpdate={shouldUpdate}
            />
            <StyledEventVisualizationContainer>
              {showEvents && (
                <AddEvents
                  onChange={() => {}}
                  freightOrder={eventsOptions}
                  sortValue=""
                  listEvents={listEvents}
                  freightId={freightId}
                  feedBackUpdade={feedBackUpdade}
                />
              )}
              <ListElement className='full-width'>
                <StyledBreadCrumb>
                  <button className='button' onClick={handleShowEvents}>
                    {showEvents ? (
                      <>
                        <i className="fas fa-arrow-left" />
                        Fechar
                      </>
                    ) : (
                      <>
                        <i className="fas fa-arrow-right" />
                        Adicionar/Exibir Eventos
                      </>
                    )}
                  </button>
                  <h2>Macros - Frete {freightId}</h2>
                </StyledBreadCrumb>
                <div className='macro-list'>
                  {uniqueFreightList.map((item, index) =>
                    renderMobileMacroList(item, index)
                  )}
                </div>
              </ListElement>
            </StyledEventVisualizationContainer>

          </Modal>
        ) : (
          <StyledBodyContainer>
            <MapsList
              mapConfig={mapConfig}
              handleImageModal={handleImageModal}
              handleUpdate={handleUpdate}
              shouldUpdate={shouldUpdate}
            />
          </StyledBodyContainer>
        )}

        <ListElement>
          <StyledBreadCrumb>
            {/* <InputText
              name="apelido"
              style={{visibility: "hidden"}}
              /> */}
            <h2>Fretes de Acompanhamento</h2>
            <div className='filtrar'>
                <SelectText
                  label="Filtrar"
                  name="sortFreight"
                  onChange={onChangeFilter}
                  options={monitorListFilter}
                  value={filterValue}
                />
            </div>
            <div>
              <SelectText
                label="Ordenar"
                name="sortFreight"
                onChange={onChangeSort}
                options={freightOrder}
                value={sortValue}
              />
            </div>
          </StyledBreadCrumb>
          {freightList?.length > 0 && (
            freightList.map((item, index) => renderMobileList(item, index))
          )}
        </ListElement>
        <Pagination
          params={pagination}
          requestPage={changePagination}
        />
      </StyledContentGeo>
    </section>
  )
}

Monitors.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default Monitors
