import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomButtonToggle } from './styles'

const ToggleButton = (props) => {
  const {
    label,
    value,
    name,
    onClick,
    className,
    isRequired,
    readOnly
  } = props

  const handleClick = (name, value) => {
    if (readOnly) return
    onClick(name, value)
  }

  return (
    <Field className={className}>
      <span>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      {value ? (
        <CustomButtonToggle onClick={() => handleClick(name, !value)}>
          <i className="fas fa-toggle-on toggleOn" /><span>Sim</span>
        </CustomButtonToggle>
      ) : (
        <CustomButtonToggle onClick={() => handleClick(name, !value)}>
          <i className="fas fa-toggle-off" /><span>Não</span>
        </CustomButtonToggle>
      )}
    </Field>
  )
}

ToggleButton.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
}

ToggleButton.defaultProps = {
  className: '',
  isRequired: false,
  label: null,
  name: null,
  readOnly: false
}

export default ToggleButton
