import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import DriversForm from '@/views/components/forms/DriversForm'
import DriversList from '../components/forms/DriversList'
import {
  getDriversData,
  postDriversData,
  putDriversData
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import { formatterNumberOnly } from '@/utils/formatter'
import Loading from '../components/Loading/Loading'
import { StyledBodyContainer } from './styles'

const DEFAULT_FORM = {
  tipo: 1,
  active: 1
}

const Drivers = (props) => {
  const { isMobile } = props

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [clientsList, setClientsList] = useState([])
  const [showFullList, setShowFullList] = useState(null)
  const [clientToEdit, setClientToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submited, setSubmited] = useState(false)
  const [showModalRequired, setShowModalRequired] = useState(false)
  const [requiredMessage, setRequiredMessage] = useState('')

  const getFullList = useCallback(async () => {
    const list = await getDriversData()
    setClientsList(list.motoristas || [])
  }, [])

  useEffect(() => {
    getFullList()
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }

  const handleEditChange = (form) => {
    setClientToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues,
      cpf: formatterNumberOnly(formValues.cpf),
      rg: formatterNumberOnly(formValues.rg) || null,
      telefone: formatterNumberOnly(formValues.telefone) || null,
      numero_cnh: formatterNumberOnly(formValues.numero_cnh) || null,
      inscricao_estadual: formatterNumberOnly(formValues.inscricao_estadual) || null,
      endereco: {
        ...formValues.endereco,
        cep: formatterNumberOnly(formValues.endereco?.cep),
      }
    }
    return form
  }
  
  function capitalizeString(str) {
    const words = str.split(" ");
    
    for (let i = 0; i < words.length; i++) {
      if (words[i] !== '') {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
      }
    }
    return words.join(" ")
  }

  const verifyIfNullOrEmpty = (value) => {
    if(value === null || value === undefined || value === "") {
      return true
    } else {
      return false
    }
  }

  const fieldRequired = (name, mensagem) => {
    setShowModalRequired('warning')
    setRequiredMessage(`É necessário preencher o campo de ${mensagem}!`)
    document.getElementById(name).focus()
  }

  const requiredFields = async (obj) => {

    if(verifyIfNullOrEmpty(obj.nome)) {
      fieldRequired("nome", "nome")
      return true
    }
    if(verifyIfNullOrEmpty(obj.cpf)) {
      fieldRequired("cpf", "cpf")
      return true
    }
    if(verifyIfNullOrEmpty(obj.rg)) {
      fieldRequired("rg", "rg")
      return true
    }
    if(verifyIfNullOrEmpty(obj.data_nascimento)) {
      fieldRequired("data_nascimento", "aniversário")
      return true
    }
    if(verifyIfNullOrEmpty(obj.telefone)) {
      fieldRequired("telefone", "telefone")
      return true
    }
    if(verifyIfNullOrEmpty(obj.numero_cnh)) {
      fieldRequired("numero_cnh", "número da CNH")
      return true
    }
    if(verifyIfNullOrEmpty(obj.mae)) {
      fieldRequired("mae", "nome da mãe")
      return true
    }
    if(verifyIfNullOrEmpty(obj.categoria_cnh)) {
      fieldRequired("categoria_cnh", "categoria da CNH")
      return true
    }
    if(verifyIfNullOrEmpty(obj.emissao_cnh)) {
      fieldRequired("validade_cnh", "validade da CNH")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.cep)) {
      fieldRequired("cep", "cep")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.logradouro)) {
      fieldRequired("logradouro", "logradouro")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.numero)) {
      fieldRequired("numero", "número")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.bairro)) {
      fieldRequired("bairro", "bairro")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.uf)) {
      fieldRequired("uf", "uf")
      return true
    }
    if(verifyIfNullOrEmpty(obj.endereco.cidade)) {
      fieldRequired("cidade", "cidade")
      return true
    }

    return false
  }

  const submitForm = async () => {
    let form = setupForm()
    
    const required = await requiredFields(form)

    if(required) {
      setLoading(false)
      setSubmited(true)
      return
    }

    form.nome = capitalizeString(form.nome)

    setLoading(true)
    const req = await postDriversData(form)

    if (req.status.active) {
      handleSearch()
      setLoading(false)
      setShowModal('sucess')
      setShowType('list-drivers')
      setFormValues(DEFAULT_FORM)
      setSubmited(false)
    } else {
      setLoading(false)
    }
  }

  const submitEditForm = async () => {
    clientToEdit.nome = capitalizeString(clientToEdit.nome)
    
    const required = await requiredFields(clientToEdit)

    if(required) {
      setLoading(false)
      setSubmited(true)
      return
    }
    
    const req = await putDriversData(clientToEdit)

    const index = clientsList.findIndex(
      (elem) => elem.id_motorista === clientToEdit.id_motorista
    )

    const newForm = [...clientsList]

    newForm[index] = clientToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setClientsList(newForm)
      setShowFullList(clientToEdit.id_motorista)
      handleSearch()
      setSubmited(false)
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
    setShowModalRequired(null)
    setRequiredMessage('')
  }

  const onShowClient = (e) => {
    setShowFullList(e === showFullList ? null : e)
  }

  const onEditClient = (e) => {
    e.nome = capitalizeString(e.nome)
    setClientToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setClientToEdit({})
  }

  const onShowConfirmModal = (client) => {
    setClientToEdit(client)
    setShowEditModal(true)
    setFormValues(DEFAULT_FORM)
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <DriversForm
              onChange={handleChange}
              formValues={formValues}
              goToDriver={onShowConfirmModal}
              isSubmited={submited}
            />
            <button className='button formButton' onClick={submitForm}>Enviar</button>
          </>
        )
      case 'list-drivers':
        return (
          <DriversList
            onShowItem={onShowClient}
            onEditItem={onEditClient}
            fullList={clientsList}
            showFullList={showFullList}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Cliente"
      size='lg'
    >
      <DriversForm
        onChange={handleEditChange}
        formValues={clientToEdit}
        isEdit
        isSubmited={submited}
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const req = await getDriversData(searchBy)
    setLoading(false)

    if (!req) {
      return setShowType(null)
    }

    setShowType('list-drivers')
    setShowFullList(req.motoristas.id_motorista)
    setClientsList(req.motoristas)
  }

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showEditModal && renderEditModal()}
      {showModalRequired && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg={requiredMessage}
        />
      )}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Motoristas"
              placeholder="Nome ou CPF"
              onSubmitSearch={handleSearch}
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

Drivers.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default Drivers
