import styled from '@emotion/styled'

export const LoadEmptyStyled = styled.div`
  width: 80%;
  margin: 20px auto;
  text-align: center;
  padding: 40px;
  border-radius: 3px;
  border: 5px solid var(--color-third-light);
  box-shadow: 5px 5px 0 0 var(--color-third);

  h2 {
    margin-bottom: 20px;

    i {
      font-size: 60px;
      color: var(--color-third);
    }

    span {
      i {
        font-size: 30px;
        margin-right: 5px;
        transition: all .4s ease-in-out;
      }

      @media (max-width: 780px) {
        i {
          transform: rotate(90deg);
          vertical-align: top;
          margin-right: -5px;
          margin-top: -10px;
        }
      }
    }
  }

  p {
    color: var(--color-primary);
    font-size: 20px;
  }
`
