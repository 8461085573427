import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {
  LOGIN,
  MATRIZ,
  DRIVER,
  CLIENTS,
  RELOGIN,
  USER,
  MONITOR,
  VEHICLE_TYPE,
  LOAD_TYPE,
} from './constants/parameters'
import Header from './views/Header'
import Modal from './views/fragments/Modal'
import Login from './views/Login'
import Clients from './views/Clients'
import SubsidiaryAndParent from './views/SubsidiaryAndParent'
import Drivers from './views/Drivers'
import ReloginPage from './views/ReloginPage'
import Users from './views/Users'
import Monitors from './views/Monitor'
import {
  getAllParams,
  deleteParams,
  setParams,
} from './constants/localStorage'
import { StyledButtons } from './styles'
import Freight from './views/Freight'
import VehicleTypes from './views/VehicleType'
import TypeLoad from './views/TypeLoad'

const INNER_WIDTH_PARAM = 1024
const INNER_WIDTH_PARAM_MOBILE = 780
const HREF_RELOGIN = window.location.href.includes('/relogin')
const HREF_LOGIN = window.location.href.includes('/login')

const DefaultRoute = () => {
  const [showMenu, setShowMenu] = useState(window.innerWidth > INNER_WIDTH_PARAM)
  const [isLogged, setIsLogged] = useState(!(HREF_RELOGIN || HREF_LOGIN))
  const [showModal, setShowModal] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < INNER_WIDTH_PARAM_MOBILE)
  const [currentUser, setCurrentUser] = useState('')

  const resize = useCallback(() => {
    window.addEventListener('resize', () => {
      setShowMenu(window.innerWidth >= INNER_WIDTH_PARAM && !(HREF_RELOGIN || HREF_LOGIN))
      setIsMobile(window.innerWidth <= INNER_WIDTH_PARAM_MOBILE)
      setIsLogged(!(HREF_RELOGIN || window.location.href.includes('/login')))
    })
  }, [])

  useEffect(() => {
    const {
      user,
    } = getAllParams()

    resize()

    if (user) setCurrentUser(user)
  }, [])

  const onConfirmLogout = () => {
    deleteParams()
    window.location = '/login'
  }

  const handleLogout = () => {
    setShowModal(true)
  }

  const renderModal = () => (
    <Modal
      closeModal={() => setShowModal(false)}
      subTitle="Deseja fazer o logout?"
    >
      <StyledButtons>
        <button
          className='button cancel'
          onClick={() => setShowModal(false)}
        >Não</button>

          <button
          className='button'
          onClick={onConfirmLogout}
        >Sim</button>
      </StyledButtons>
    </Modal>
  )

  const handleUserInfo = (token, user, profile, novo_usuario) => {
    setParams('authentication', token)
    setParams('user', user)
    setParams('profile', profile)
    setParams('novo_usuario', novo_usuario)
    setCurrentUser(user)
  }

  return (
    <Router>
      {showModal && renderModal()}
      <Header
        showMenu={showMenu}
        showHeader
        isLogged={isLogged}
        onLogoutClick={handleLogout}
        user={currentUser}
      />

      <Routes>
        <Route
          path={`/${LOGIN}`}
          element={<Login
            userInfo={handleUserInfo}
          />}
        />
        <Route
          path={`/${RELOGIN}`}
          element={<ReloginPage />}
        />
        <Route
          path="/"
          element={<Freight isMobile={isMobile} />}
        />
        <Route
          path={`/${CLIENTS}`}
          element={<Clients isMobile={isMobile} />}
        />
        <Route
          path={`/${MATRIZ}`}
          element={<SubsidiaryAndParent isMobile={isMobile} />}
        />
        <Route
          path={`/${DRIVER}`}
          element={<Drivers isMobile={isMobile} />}
        />
        <Route
          path={`/${USER}`}
          element={<Users isMobile={isMobile} />}
        />
        <Route
          path={`/${MONITOR}`}
          element={<Monitors isMobile={isMobile} />}
        />
        <Route
          path={`/${VEHICLE_TYPE}`}
          element={<VehicleTypes isMobile={isMobile} />}
        />
        <Route
          path={`/${LOAD_TYPE}`}
          element={<TypeLoad isMobile={isMobile} />}
        />
      </Routes>
    </Router>
  )
}

export default DefaultRoute


