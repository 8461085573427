import styled from '@emotion/styled'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: 1024px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  &.full-width {
    max-width: calc(100% - 30px);
  }
`
