import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import TypeVehicleForm from '@/views/components/forms/TypeVehicleForm'
import TypeVehicleList from '../components/forms/TypeVehicleList'
import {
  getTypeVehicleData,
  postTypeVehicleData,
  putTypeVehicleData
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import Loading from '../components/Loading/Loading'
import { StyledBodyContainer } from './styles'

const DEFAULT_FORM = {
  tipo: 1,
  active: 1
}

const VehicleTypes = (props) => {
  const { isMobile } = props

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [typesList, setTypesList] = useState([])
  const [showFullList, setShowFullList] = useState(null)
  const [clientToEdit, setClientToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const getFullList = useCallback(async () => {
    const list = await getTypeVehicleData()
    setTypesList(list.tipos_veiculos || [])
  }, [])

  useEffect(() => {
    getFullList()
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }

  const handleEditChange = (form) => {
    setClientToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues
    }
    return form
  }

  const submitForm = async () => {
    const form = setupForm()

    setLoading(true)

    const req = await postTypeVehicleData(form)
    if (req.status.active) {
      setLoading(false)
      setShowModal('sucess')
      setShowType('list-type-vehicle')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }
  }

  const submitEditForm = async () => {
    const req = await putTypeVehicleData(clientToEdit)

    const index = typesList.findIndex(
      (elem) => elem.id_tipoveiculo === clientToEdit.id_tipoveiculo
    )

    const newForm = [...typesList]

    newForm[index] = clientToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setTypesList(newForm)
      setShowFullList(clientToEdit.id_tipoveiculo)
      handleSearch()
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onShowClient = (e) => {
    setShowFullList(e === showFullList ? null : e)
  }

  const onEditClient = (e) => {
    setClientToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setClientToEdit({})
  }

  const onShowConfirmModal = (client) => {
    setClientToEdit(client)
    setShowEditModal(true)
    setFormValues(DEFAULT_FORM)
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <TypeVehicleForm
              onChange={handleChange}
              formValues={formValues}
              goToDriver={onShowConfirmModal}
            />
            <button className='button formButton' onClick={submitForm}>Enviar</button>
          </>
        )
      case 'list-type-vehicle':
        return (
          <TypeVehicleList
            onShowItem={onShowClient}
            onEditItem={onEditClient}
            fullList={typesList}
            showFullList={showFullList}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Tipo do Veículo"
      size='lg'
    >
      <TypeVehicleForm
        onChange={handleEditChange}
        formValues={clientToEdit}
        isEdit
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const req = await getTypeVehicleData(searchBy)
    setLoading(false)

    if (!req) {
      return setShowType(null)
    }

    setShowType('list-type-vehicle')
    setShowFullList(req.tipos_veiculos.id_tipoveiculo)
    setTypesList(req.tipos_veiculos)
  }

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showEditModal && renderEditModal()}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Tipos de Veículos"
              placeholder="Nome"
              onSubmitSearch={handleSearch}
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

VehicleTypes.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default VehicleTypes
