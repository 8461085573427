import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import Accordeon from '@/views/components/Accordeon'
import { ClientsContainer } from './styles'

const TypeLoadList = (props) => {
  const {
    onShowItem,
    onEditItem,
    fullList,
    showFullList
  } = props

  const handleShowType = (params) => {
    onShowItem(params.id_tipocarga)
  }

  const renderList = (typeLoad, i) => (
    <Accordeon
      key={i}
      params={typeLoad}
      showItem={Boolean(showFullList === typeLoad.id_tipocarga)}
      onShowItem={handleShowType}
      onEditItem={onEditItem}
      title="Nome"
      titleValue={typeLoad.nome}
    >
      <>
        <div>
          <span>Nome</span>
          <p className='capitalize'>{typeLoad.nome || '-'}</p>
        </div>
        <div>
          <span>Status Ativo</span>
          <ToggleButton
            value={Number(typeLoad.status) === 1 || false}
            className="styledToggle"
            readOnly
          />
        </div>
      </>
    </Accordeon>
  )

  return (
    <ClientsContainer>
      {fullList.map((typeLoad, index) => renderList(typeLoad, index))}
    </ClientsContainer>
  )
}

TypeLoadList.propTypes = {
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFullList: PropTypes.number
}

TypeLoadList.defaultProps = {
  showFullList: null
}

export default TypeLoadList
