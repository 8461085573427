import React from 'react'
import PropTypes from 'prop-types'
import { Logo, Background, Container } from './styles'

const Loading = ({ noBackground, msg, small }) => (
  <>
    {!noBackground && <Background />}
    <Container className={noBackground ? 'default' : ''}>
      <div>
        <Logo className={small ? 'small' : ''}>
          <span />
          <span />
          <span />
          <span />
        </Logo>
        {msg && <p>{msg}</p>}
      </div>
    </Container>
  </>
)

Loading.propTypes = {
  noBackground: PropTypes.bool,
  msg: PropTypes.string,
  small: PropTypes.bool
}

Loading.defaultProps = {
  noBackground: false,
  msg: null,
  small: false
}

export default Loading
