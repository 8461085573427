import styled from '@emotion/styled'

export const StyledHeaderContainer = styled.div`
  width: 100%;
  background-color: var(--color-hover-light-almost);
  border-bottom: 5px solid var(--color-third);
  left: 0;
  top: 0;
  position: absolute;
  color: var(--color-gray-light);
  min-height: 80px;
  z-index: 99;
`

export const StyledLogo = styled.div`
  width: 190px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  float: left;

  img {
    width: 150px;
    max-width: 80%;
  }

  @media (max-width: 1023px) {
    width: 220px;

    img {
      width: 240px;
    }
  }
`

export const StyledMenuContainer = styled.ul`
  display: inline-block;
  width: calc(100% - 490px);
  vertical-align: top;
  display: flex;
  float: left;

  & > li {
    color: var(--color-bg-dark);
    display: inline-block;
    position: relative;
    margin-right: 20px;
    height: 80px;
    display: flex;
    align-items: center;

    a {
      color: var(--color-bg-dark);
      cursor: pointer;
      line-height: 42px;
      height: 42px;
      padding-right: 3px;
      display: block;
      font-size: 14px;
      font-weight: 600;

      & > span {
        display: block;

        i {
          margin: 0 5px;
        }
      }

      &.active, &:hover {
        border-bottom: 4px dashed var(--color-secondary);
      }
    }

    .subMenu {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: var(--color-third);
      z-index: 10;
      padding: 10px 20px;
      width: 250px;
      box-shadow: 0 7px 5px 1px rgba(0, 0, 0, .4);
    }

    &.sub {
      width: 80px;

      &.active {
        background-color: var(--color-third);
      }

      a {
        &:hover, &.active {
          border-bottom: none;
          background-color: var(--color-primary);
          color: var(--color-light);
        }
      }

      & > a {
        &:hover {
          background-color: transparent;
          color: var(--color-bg-dark);
        }
      }
    }
  }

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 10px;
    width: 100%;
    border-top: 1px solid var(--color-third-light);
    display: block;

    li {
      width: 100%;
      text-align: center;
      display: block;
      height: auto;
      margin: 0 0 10px;
      padding-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        height: auto;
        line-height: 40px;
        color: var(--color-third-light);
        padding: 10px 0;
        box-shadow: none !important;
        border: 2px dashed var(--color-primary);

        &.active, &:hover {
          border: 2px dashed var(--color-secondary);
        }
      }

      ul {
        opacity: 1;
        z-index: 10;
        top: 100%;
        position: relative;
        left: 0;
        top: auto;
        border: none;
        padding: 0;
        
        li {
          background-color: transparent;
          a {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    vertical-align: middle  ;
  }
`

export const StyledAvatarContainer = styled.div`
  display: inline-block;
  width: 300px;
  color: var(--color-primary);

  & > div {
    display: grid;
    align-items: center;
    height: 80px;
    grid-template-columns: calc(100% - 40px) 40px;

    & > div {
      display: inline-block;
      margin-right: 15px;

      span {
        & > span {
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
  }

  & > span {
    font-size: 13px;
    display: inline-block;
  }

  button {
    vertical-align: middle;
    background-color: transparent;
    border: none;
    font-size: 23px;
    color: var(--color-bg-dark);

    span { display: none;}

    &:hover {
      background-color: var(--color-bg-dark);
      color: var(--color-light);
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 15px;

    & > div {
      display: block;
      text-align: center;
      margin: 20px 0;
      color: var(--color-third-light);
      font-weight: 600;
    }

    button {
      width: 100%;
      margin-bottom: 20px;
      color: var(--color-third-light);
      font-weight: 600;

      i {
        vertical-align: middle;
      }

      span {
        display: inline-block;
        font-size: 15px;
        margin-left: 10px;
        vertical-align: middle;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
`

export const StyledIcon = styled.div`
  border-radius: 50%;
  background-color: var(--color-third-light);
  color: var(--color-primary);
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
`

export const StyledButtonMobile = styled.button`
  background-color: transparent;
  border: none;
  float: right;
  font-size: 21px;
  color: var(--color-primary);
  width: 80px;
  height: 80px;
  line-height: 80px;

  &:hover, &.active {
    background-color: var(--color-primary);
    color: var(--color-third-light);
  }
`

export const StyledMenuMobile = styled.div`
  width: 400px;
  position: absolute;
  top: 100%;
  max-width: 100%;
  background-color: var(--color-primary);
  right: 0;
`
