import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LOGO from '@/assets/menu-logo.png'
import Menu from './Menu'
import Avatar from './Avatar'
import { useNavigate  } from 'react-router-dom'
// import { LOGIN } from '@/constants/parameters'
// import { RedirectPage } from '@/constants/redirectPage'
// import { getPersistenceAuthentication } from '@/constants/requests'
// import { useCallback } from 'react'
import {
  StyledHeaderContainer,
  StyledLogo,
  StyledButtonMobile,
  StyledMenuMobile,
} from './styles'
import Container from '../components/Container'

const Header = (props) => {
  const {
    showMenu,
    showHeader,
    isLogged,
    onLogoutClick,
    user
  } = props
  const navigate = useNavigate()

  const [openedMenu, setOpenedMenu] = useState(false)

  // const checkPersistence = useCallback(async (currentURL) => {
  //   const persistence = await getPersistenceAuthentication()
  //   if (currentURL === `/${LOGIN}`) return
  //   if (!persistence) {
  //     window.location = LOGIN
  //   }
  // }, [])

  useEffect(() => {
    // const currentURL = window.location.pathname
    // checkPersistence(currentURL)
  }, [])

  const handleMenuclick = () => {
    setOpenedMenu(false)
  }

  return (
    <StyledHeaderContainer>
      <Container>
        {/* {RedirectPage()} */}
        <StyledLogo onClick={() => navigate('/')}>
          <img src={LOGO} alt="Logo Rodorrica Header" />
        </StyledLogo>
        {(showHeader && isLogged) && (
          <>
            {showMenu ? (
              <>
                <Menu isMobile={!showMenu}/>
                <Avatar
                  onLogoutClick={onLogoutClick}
                  user={user}
                />
              </>
            ): (
              <>
                <StyledButtonMobile
                  className={openedMenu ? 'active' : ''}
                  onClick={() => setOpenedMenu(!openedMenu)}
                >
                  <i className="fas fa-bars" />
                </StyledButtonMobile>
                {openedMenu && (
                  <StyledMenuMobile>
                    <Avatar
                      showMenu={showMenu}
                      onLogoutClick={onLogoutClick}
                      user={user}
                    />
                    <Menu
                      onMenuClick={handleMenuclick}
                      isMobile={!showMenu}
                    />
                  </StyledMenuMobile>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </StyledHeaderContainer>
  )
}

Header.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired
}

export default Header
