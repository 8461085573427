import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomRadioButton, LabelInputRadio } from './styles'

const InputRadio = (props) => {
  const {
    label,
    options,
    value,
    name,
    onClick,
    className,
    isRequired,
  } = props

  const renderOptions = (item, i) => {
    if (item.value === value) return (
      <CustomRadioButton key={i}>
        <span>
          {item.label}
        </span>
        <i className="fas fa-dot-circle toggleOn" />
      </CustomRadioButton>
    )

    return (
      <CustomRadioButton onClick={() => onClick(name, item)} key={i}>
        <span>
          {item.label}
        </span>
        <i className="far fa-circle" />
      </CustomRadioButton>
    )
  }

  return (
    <Field className={className}>
      <LabelInputRadio>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </LabelInputRadio>
      {options.map((item, index) => renderOptions(item, index))}
    </Field>
  )
}

InputRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
}

InputRadio.defaultProps = {
  className: '',
  isRequired: false,
}

export default InputRadio
