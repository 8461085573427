import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import LOGO from '@/assets/menu-logo.png'
import BG from '@/assets/b2.jpg'
import Modal from '../fragments/Modal'
import { hashGenerator } from '@/constants/hashGenerator'
import { ADMIN_PROFILE } from '@/constants/parameters'
import { postLoginAccess, getUserIP, putChangePassord } from '@/constants/requests'
import {
  StyledSection,
  StyledLogin,
  StyledImage,
  StyledContainer,
} from './styles'

const Login = (props) => {
  const { userInfo } = props

  const [formValues, setFormValues] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [userCode, setUserCode] = useState(null)
  const [modalType, setModalType] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSent = async () => {
    const ip = await getUserIP('bd9a8646a5a7b0')
    const data = {
      usuario: formValues.user,
      senha: formValues.password,
      ip: ip.data.ip
    }
    setIsLoading(true)

    await postLoginAccess(data)
      .then((res) => {
        const { status } = res.data

        if (!status.active) {
          return setShowModal(true)
        }
        const profile = hashGenerator(ADMIN_PROFILE, status.token)

        userInfo(status.token, formValues.user, profile, status.novo_usuario)

        if (status.novo_usuario === 0) {
          window.location = "/"
          return
        }

        setUserCode(status.usr_codigo)
        setShowNewPassword(true)
      })
      .finally(() => setIsLoading(false))
  }

  const closeModal = () => {
    setShowModal(false)
    setModalType(null)
  }

  const closeModalCallBack = () => {
    closeModal()
    window.location = "/"
  }

  const renderModal = () => {
    if (modalType === 'change-passord-success') {
      return (
        <Modal
          type="success"
          title="Confirmado"
          subTitle="Senha alterada com sucesso"
          closeModal={closeModalCallBack}
        />
      )
    }
    if (modalType === 'wrong-passwords') {
      return (
        <Modal
          type="alert"
          title="Verifique..."
          subTitle="As senhas não parecem iguais..."
          closeModal={closeModal}
        />
      )
    }

    return (
      <Modal
        type="alert"
        title="Opa!"
        subTitle="Parece que o usuário não está ativo"
        closeModal={closeModal}
      />
    )
  }

  const submitUser = (e) => {
    if (e.key === 'Enter') {
      handleSent()
    }
  }

  const handleChangePassword = async () => {
    setIsLoading(true)
    if (formValues.password !== formValues.confirm_password) {
      setModalType('wrong-passwords')
      setShowModal(true)
      setIsLoading(false)
      return
    }

    const data = {
      usr_codigo: userCode,
      password: formValues.password,
      confirm_password: formValues.confirm_password
    }
    const put = await putChangePassord(data)
    setIsLoading(false)
    if (put.status.active) {
      setModalType('change-passord-success')
      setShowModal(true)
    }
  }

  const backButton = () => {
    setFormValues({})
    closeModal()
    setIsLoading(false)
    setShowNewPassword(null)
  }

  return (
    <StyledContainer style={{backgroundImage: `url(${BG})`}}>
      {showModal && renderModal()}
      <StyledSection>
        <StyledLogin>
          <div className='left'>
            <div>
              <article>
                {showNewPassword ? (<div>
                  <h2>Altear Senha</h2>
                  <InputText
                    label="Senha"
                    value={formValues.password || ''}
                    name="password"
                    type="password"
                    onChange={handleChange}
                  />
                  <InputText
                    label="Senha"
                    value={formValues.confirm_password || ''}
                    name="confirm_password"
                    type="password"
                    onChange={handleChange}
                  />
                  <button
                    className="button"
                    onClick={handleChangePassword}
                    disabled={isLoading}
                  >Alterar Senha
                  </button>
                  <button
                    className="button cancel"
                    onClick={backButton}
                  >Voltar
                  </button>
                </div>) : (
                  <>
                    <h2><i className="fas fa-sign-in-alt" />Fazer Login</h2>
                    <div onKeyDown={submitUser}>
                      <InputText
                        label="Nome"
                        value={formValues.user || ''}
                        name="user"
                        onChange={handleChange}
                      />

                      <InputText
                        label="Senha"
                        value={formValues.password || ''}
                        name="password"
                        type="password"
                        onChange={handleChange}
                      />

                      <button
                        className={`button ${isLoading && 'loading'}`}
                        onClick={handleSent}
                        disabled={isLoading}
                      >
                        {isLoading ? (<i className="fas fa-spinner" />) : 'Logar'}
                      </button>
                    </div>
                  </>
                )}
              </article>
            </div>
          </div>
          <div className='right'>
            <StyledImage>
              <img src={LOGO} alt="Logo Rodorrica Login" />
            </StyledImage>
          </div>
        </StyledLogin>
      </StyledSection>
    </StyledContainer>
  )
}

Login.propTypes = {
  userInfo: PropTypes.func.isRequired,
}

export default Login
