import styled from '@emotion/styled'

export const UserContainer = styled.div`
  width: 100%;
  padding: 10px;
`

export const BoxUser = styled.div`
  background-color: var(--color-gray-lighter);
  margin-bottom: 25px;
  box-shadow: 3px 3px  0 0 var(--color-third);
  display: flex;

  &.active {
    background-color: var(--color-third-light);
  }

  .styledToggle {
    & > div {
      padding-top: 0;
    }
  }

  & > div {
    padding: 15px;
    width: 100%;

    &.clickArea {
      cursor: pointer;

      &:hover {
        background-color: var(--color-hover-black-ultra-light);
      }
    }

    & > div {
      margin-top: 15px;

      p {
        overflow-wrap: break-word;
      }

      &:first-of-type {
        margin-top: 0;

        p {
          font-weight: 800;
          color: var(--color-primary);
          font-size: 20px;
          letter-spacing: .5px;
        }
      }

      & > span {
        font-weight: 800;
        font-size: 12px;
        display: block;
        margin-bottom: 5px;
        text-transform: uppercase;
        color: var(--color-gray-dark);
      }
    }

    h4 {
      text-align: left;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid;
      color: var(--color-third);
    }

    &.buttons {
      display: flex;
      width: 80px;

      button {
        width: 42px;
        height: 42px;
        justify-content: center;
        align-items: center;
        background-color: var(--color-third);
        padding: 0;
        text-align: center;
        margin: 0;
        
        i {
          font-size: 25px;
        }

        &.ver:hover {
          background-color: var(--color-success);
        }

        &.editar:hover {
          background-color: var(--color-warning);
          color: var(--color-success);
        }

        &.bloquear:hover {
          background-color: var(--color-alert);
        }
      }
    }

    @media (min-width: 520px) {
      display: grid;
      grid-template-columns: 50% 50%;

      & > div {
        &:first-of-type {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &.address {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      &.buttons {
        span {
          justify-content: center
        }
      }
    }
  }

  @media (min-width: 1280px) {
    display: flex;

    & > div {
      width: 100%;

      &.buttons {
        margin: 0;
        align-items: center;

        .button {
          margin: 0;
        }
      }
    }
  }
`
