import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledBGModal,
  StyledModalContent,
} from './styles'

const SIZE = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
}

const Modal = (props) => {
  const {
    children,
    closeModal,
    title,
    subTitle,
    size, // sm, md, lg or xl
    type, // warning, alert or success
    customClass
  } = props

  useEffect(() => {
    document.body.setAttribute('scroll', 'no')
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.removeAttribute('scroll')
      document.body.style.overflow = 'initial'
    }
  }, [])

  const handleClose = () => {
    closeModal()
  }

  const renderSize = () => {
    if (size === SIZE.lg) {
      return SIZE.lg
    }

    if (size === SIZE.md) {
      return SIZE.md
    }

    if (size === SIZE.xl) {
      return SIZE.xl
    }

    return SIZE.sm
  }

  return (
    <>
      <StyledBGModal className={customClass} >
        <StyledModalContent
          className={`${renderSize()} ${type}`}
        >
          <button className={`button close ${type}`}  onClick={() => handleClose()}>
            <i className="fas fa-times" />
          </button>
          <div>
            {title && (
              <h2 className={type}>{title}</h2>
            )}
            {subTitle && (
              <p className='title'>{subTitle}</p>
            )}
            {children}
          </div>
        </StyledModalContent>
      </StyledBGModal>
    </>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  customClass: PropTypes.string
}

Modal.defaultProps = {
  children: null,
  title: null,
  subTitle: null,
  size: SIZE.sm,
  type: '',
  customClass: ''
}

export default Modal
