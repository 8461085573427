import styled from '@emotion/styled'

export const ElementContent = styled.div`
  width: 100%;
`

export const ListElement = styled.div`
  display: block;
  border: 1px solid var(--color-primary);

  @media (max-width: 779px) {
  }

  div {
    &.row {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      align-items: center;

      & > div {
        span {
          font-weight: 700;
          color: var(--color-gray-dark);
          font-size: 11px;
          display: block;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        &.name {
          width: 180px;
          text-transform: capitalize;
        }

        button {
          border: 1px solid var(--color-primary);
          background-color: var(--color-primary);
          border-radius: 3px;
          color: var(--color-light);
          font-size: 16px;
          padding: 5px 8px 2px;

          i {
            margin: 0 3px;
          }

          &:hover {
            background-color: var(--color-light);
            color: var(--color-primary);
          }
        }
      }

      &:nth-of-type(2n+1) {
        background-color: var(--color-third-light);
      }
    }
  }

  @media (min-width: 1280px) {
    ul {
      li {
        text-transform: capitalize !important;
        padding: 5px;
        display: grid;
        grid-gap: 5px;
        align-items: center;
        grid-template-columns: 50px 200px repeat(2, calc(50% - 375px)) 116px 116px 89px 70px 70px;

        & > div {
          font-size: 14px;

          button {
            border: 1px solid var(--color-primary);
            background-color: var(--color-primary);
            border-radius: 3px;
            color: var(--color-light);
            font-size: 16px;
            padding: 5px 8px 2px;

            i {
              margin: 0 3px;
            }

            &:hover {
              background-color: var(--color-light);
              color: var(--color-primary);
            }
          }

          &:first-of-type {
            font-weight: 600;
          }
        }

        &:nth-of-type(2n+1) {
          background-color: var(--color-third-light);
        }

        &.table-header {
          div {
            span {
              font-weight: 800;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1380px) {
    ul {
      li {
        grid-template-columns: 50px 280px repeat(2, calc(50% - 470px)) 136px 136px 100px 65px 80px 50px;
      }
    }

    .lista-ofertas {
      li {
        grid-template-columns: 75px 430px 240px 150px 150px 70px 70px;
      }
    }
  }
`

export const StyledModal = styled.div`
  text-align: center;

  .margin {
    margin-bottom: 15px;
  }

  p {
    margin: 20px 0;
  }

  button {
    margin-top: 5px;
  }

  .div-confirm {
    display: flex;
    width: 100%;
    gap: 10px;
  }

  .confirm {
    width: 65%;
  }

  .denied {
    width: 35%;
  }

  .label-input {
    color: var(--color-primary);
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
  }
`


export const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 10px;
  }
`

