import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'

const Container = ({ children, fullWidth }) => (
  <StyledContainer
    className={fullWidth ? 'full-width' : ''}
  >
    {children}
  </StyledContainer>
)

Container.propTypes = {
  children: PropTypes.any.isRequired,
  fullWidth: PropTypes.bool,
}

Container.defaultProps = {
  fullWidth: false
}

export default Container
