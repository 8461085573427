import React from 'react'
import PropTypes from 'prop-types'
import {
  Field,
  CustomLoading,
  StyledHint,
  CustomSearchButton,
  StyledInputSearch
} from './styles'

const InputSearch = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    type,
    onBlur,
    isRequired,
    className,
    isLoading,
    isDisabled,
    isError,
    isSuccess,
    hint,
    placeholder,
    onSubmitSearch,
  } = props

  const renderType = () => {
    if (isSuccess) return 'success'
    if (isError) return 'alert'
    return ''
  }

  return (
    <Field className={className}>
      <span>
        {Boolean(label) && label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      <StyledInputSearch
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        type={type}
        className={renderType()}
        onBlur={onBlur}
        required={isRequired}
        disabled={isDisabled || isLoading}
        placeholder={placeholder}
        autoComplete={name}
      />

      {isLoading ? (
        <CustomLoading className="fas fa-spinner" />
      ) : (
        <CustomSearchButton onClick={onSubmitSearch}>
          <i className="fas fa-search" />
        </CustomSearchButton>
      )}
      {(isError && !isSuccess) && (
        <StyledHint className={isSuccess ? 'success' : ''}>
          {hint}
        </StyledHint>
      )}
    </Field>
  )
}

InputSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

InputSearch.defaultProps = {
  type: 'text',
  onBlur: null,
  isRequired: false,
  className: '',
  label: '',
  isLoading: false,
  isDisabled: false,
  hint: '',
  placeholder: '',
  isError: false,
  isSuccess: false,
}

export default InputSearch
