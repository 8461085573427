import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import Loading from '../components/Loading/Loading'
import UserForm from '@/views/components/forms/UserForm'
import UserList from '../components/forms/UserList'
import {
  getUserData,
  postUserData,
  putUserData
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import { formatterNumberOnly } from '@/utils/formatter'
import { StyledBodyContainer } from './styles'

const DEFAULT_FORM = {
  tipo: 1,
  active: 1
}

const Users = (props) => {
  const { isMobile } = props

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [userList, setUserList] = useState([])
  const [showFullList, setShowFullList] = useState(null)
  const [userToEdit, setUserToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPasswordAlertModal, setShowPasswordAlertModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const getFullList = useCallback(async () => {
    const list = await getUserData()
    setUserList(list.usuarios || [])
  }, [])

  useEffect(() => {
    getFullList()
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }

  const handleEditChange = (form) => {
    setUserToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues,
      telefone: formatterNumberOnly(formValues.telefone) || null
    }
    return form
  }

  const submitForm = async () => {
    const form = setupForm()
    if (form.password !== form.confirm_password) {
      setShowPasswordAlertModal(true)
      return
    }
    setLoading(true)

    const req = await postUserData(form)

    if (req.status.active) {
      setShowModal('sucess')
      setShowType('list-user')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }
    setLoading(false)
  }

  const submitEditForm = async () => {
    let notObject = false
    let arrayFiliais = []
    for (let i = 0; i < userToEdit.filiais.length; i++) {
      const element = userToEdit.filiais[i];
      if(typeof element !== 'object' && element !== null) {
        let object = {
          id_filial: element,
        }
        arrayFiliais.push(object)
        notObject = true
      }
    }

    if (notObject) {
      userToEdit.filiais = arrayFiliais
    }

    if (userToEdit.password !== userToEdit.confirm_password) {
      setShowPasswordAlertModal(true)
      return
    }

    const req = await putUserData(userToEdit)

    const index = userList.findIndex(
      (elem) => elem.USR_CODIGO === userToEdit.USR_CODIGO
    )

    const newForm = [...userList]

    newForm[index] = userToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setUserList(newForm)
      setShowFullList(userToEdit.USR_CODIGO)
      handleSearch()
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onShowUser = (e) => {
    setShowFullList(e === showFullList ? null : e)
  }

  const onEditUser = (e) => {
    setUserToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setUserToEdit({})
  }

  const onShowConfirmModal = (user) => {
    setUserToEdit(user)
    setShowEditModal(true)
    setFormValues(DEFAULT_FORM)
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <UserForm
              onChange={handleChange}
              formValues={formValues}
              goToDriver={onShowConfirmModal}
            />
            <button className='button formButton' onClick={submitForm}>Enviar</button>
          </>
        )
      case 'list-user':
        return (
          <UserList
            onShowItem={onShowUser}
            onEditItem={onEditUser}
            fullList={userList}
            showFullList={showFullList}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Usuário"
      size='md'
    >
      <UserForm
        onChange={handleEditChange}
        formValues={userToEdit}
        isEdit
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  const renderPasswordAlertModal = () => (
    <DefaultModalAlert
      msg="Senhas não coincidem!"
      closeModal={() => setShowPasswordAlertModal(false)}
    />
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const req = await getUserData(searchBy, 1)
    setLoading(false)

    if (!req) {
      return setShowType(null)
    }

    setShowType('list-user')
    setShowFullList(req.usuarios.USR_CODIGO)
    setUserList(req.usuarios)
  }

  return (
    <section className='section'>
      {showPasswordAlertModal && renderPasswordAlertModal()}
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showEditModal && renderEditModal()}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Usuários"
              onSubmitSearch={handleSearch}
              placeholder="Login ou Nome"
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

Users.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default Users
