import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import InputRadio from '@/views/fragments/form/InputRadio'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import DefaultModalConfirm from '@/views/fragments/modalTypes/DefaultModalConfirm'
import { getCNPJInfo, getRegisterClient, postCEPConsult, getCpfValid } from '@/constants/requests'
import { CPF_CNPJ } from '@/constants/optionsValues'
import DefaultModalAlert from '@/views/fragments/modalTypes/DefaultModalAlert'
import {
  formatterCEP,
  formatterCNPJ,
  formatterCPF,
  formatterCPForCNPJ,
  formatterNumberOnly,
  formatterPhone
} from '@/utils/formatter'
import {
  FormContainer,
  StyledBasicData,
  StyledAddressData
} from './styles'

const MAX_LENGTH = {
  onze: 11,
  quatorze: 14
}

const ClientForm = (props) => {
  const {
    onChange,
    formValues,
    isEdit,
    goToClient
  } = props

  const [disabledList, setDisabledList] = useState({})
  const [showConfirmModal, setShowConfirmModal] = useState(null)
  const [clientToShow, setClientToShow] = useState(null)
  const [validCpf, setValidCpf] = useState(false)

  const handleChange = (e, ml) => {
    const { value, name} = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value
    })
  }

  const handleChangeAddress = (e) => {
    const { value, name} = e.target

    const newValue = {
      ...formValues,
      endereco: {
        ...formValues.endereco,
        [name]: value
      }
    }

    onChange(newValue)
  }

  const handleChangeToggle = (name, bool) => {
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  const handleInputRadio = (name, item) => {
    const newValue = {
      ...formValues,
      [name]: item.value
    }

    onChange(newValue)
  }

  const onBlurAddress = async () => {
    if (!formValues?.endereco?.cep) return
    const req = await postCEPConsult(formValues.endereco.cep)
    const values = {
      ...formValues,
      endereco: {
        ...formValues.endereco,
        bairro: req?.dados?.bairro,
        cidade: req?.dados?.localidade,
        logradouro: req?.dados?.logradouro,
        uf: req?.dados?.uf
      }
    }

    const list = {
      bairro: Boolean(req?.dados?.bairro),
      cidade: Boolean(req?.dados?.localidade),
      logradouro: Boolean(req?.dados?.logradouro),
      uf: Boolean(req?.dados?.uf)
    }

    setDisabledList(list)
    onChange(values)
  }

  const handleBlurDocument = async (isCnpj) => {
    if (!formValues.doc_number) return

    const doc = formatterNumberOnly(formValues.doc_number)
    const req = await getRegisterClient(doc)
    const match = req.clientes.find((el) => el.doc_number === doc)

    if (match && req.status.active) {
      setClientToShow(req.clientes[0])
      setShowConfirmModal(true)
      return
    }

    if (isCnpj) {
      const cnpj = await getCNPJInfo(doc)
      const form = {
        ...formValues,
        email: cnpj.empresa?.email || '',
        endereco: {
          uf: cnpj.empresa?.uf || '',
          cidade: cnpj.empresa?.municipio || '',
          complemento: cnpj.empresa?.complemento || '',
          numero: cnpj.empresa?.numero || '',
          bairro: cnpj.empresa?.bairro || '',
          logradouro: cnpj.empresa?.logradouro || '',
          cep: cnpj.empresa?.cep || ''
        },
        nome_fantasia: cnpj.empresa?.fantasia || '',
        razao_social: cnpj.empresa?.nome || '',
        telefone: cnpj.empresa?.telefone || '',
      }

      const list = {
        uf: Boolean(cnpj?.empresa?.uf),
        cidade: Boolean(cnpj?.empresa?.municipio),
        bairro: Boolean(cnpj?.empresa?.bairro),
        logradouro: Boolean(cnpj?.empresa?.logradouro),
        cep: Boolean(cnpj?.empresa?.cep),
        nome_fantasia: Boolean(cnpj?.empresa?.fantasia),
        razao_social: Boolean(cnpj?.empresa?.nome),
      }

      setDisabledList(list)

      onChange(form)
    } else {
      const req = await getCpfValid(doc)
      setValidCpf(!req.cpf)
    }
  }

  const goToRegisteredClient = () => {
    goToClient(clientToShow)
    setShowConfirmModal(false)
  }
  
  const renderCpfAlertModal = () => (
    <DefaultModalAlert
      msg="CPF está invalido, verifique!"
      closeModal={() => setValidCpf(false)}
    />
  )

  const renderConfirmModal = () => (
    <DefaultModalConfirm
      msg={`Cliente com documento ${formatterCPForCNPJ(clientToShow.doc_number)} já cadastrado, deseja visualizar cliente?`}
      closeModal={() => setShowConfirmModal(false)}
      callBack={goToRegisteredClient}
    />
  )

  return (
    <FormContainer>
      {showConfirmModal && renderConfirmModal()}
      {validCpf && renderCpfAlertModal()}
      <StyledBasicData>
        <h3>Dados Básicos</h3>
        {!isEdit && (<InputRadio
          label="Tipo"
          name="tipo"
          options={CPF_CNPJ}
          value={formValues.tipo || 1}
          onClick={handleInputRadio}
        />)}
        {formValues.tipo === 1 ? (
          <>
            <InputText
              value={formatterCPF(formValues.doc_number) || ''}
              name="doc_number"
              onChange={(e) => handleChange(e, MAX_LENGTH.onze)}
              label="CPF"
              isDisabled={isEdit}
              onBlur={() => handleBlurDocument(false)}
            />
            <InputText
              value={formValues.nome || ''}
              name="nome"
              onChange={handleChange}
              label="Nome"
            />
            <InputText
              value={formValues.apelido || ''}
              name="apelido"
              onChange={handleChange}
              label="Apelido"
            />
            <InputText
              value={formValues.rg || ''}
              name="rg"
              onChange={handleChange}
              label="RG"
            />
          </>
        ) : (
          <>
            <InputText
              value={formatterCNPJ(formValues.doc_number) || ''}
              name="doc_number"
              onChange={(e) => handleChange(e, MAX_LENGTH.quatorze)}
              label="CNPJ"
              isDisabled={isEdit}
              onBlur={() => handleBlurDocument(true)}
            />
            <InputText
              value={formValues.inscricao_estadual || ''}
              name="inscricao_estadual"
              onChange={handleChange}
              label="Inscrição Estadual"
              isDisabled={disabledList.inscricao_estadual}
            />
            <InputText
              value={formValues.nome_fantasia || ''}
              name="nome_fantasia"
              onChange={handleChange}
              label="Nome Fantasia"
              isDisabled={disabledList.nome_fantasia}
            />
            <InputText
              value={formValues.razao_social || ''}
              name="razao_social"
              onChange={handleChange}
              label="Razão Social"
              isDisabled={disabledList.razao_social}
            />
          </>
        )}
        <InputText
          value={formValues.email || ''}
          name="email"
          onChange={handleChange}
          label="Email"
        />
        <InputText
          value={formatterPhone(formValues.telefone) || ''}
          name="telefone"
          onChange={handleChange}
          label="Telefone"
        />
        {isEdit && (
          <ToggleButton
            label="Status Ativo?"
            value={formValues.status === 1 || false}
            name="status"
            onClick={handleChangeToggle}
          />
        )}
      </StyledBasicData>
      <StyledAddressData>
        <h3>Endereço</h3>
        <InputText
          value={formatterCEP(formValues?.endereco?.cep) || ''}
          name="cep"
          onChange={handleChangeAddress}
          label="CEP"
          onBlur={onBlurAddress}
          isDisabled={disabledList.cep}
        />

        <InputText
          value={formValues?.endereco?.logradouro || ''}
          name="logradouro"
          onChange={handleChangeAddress}
          label="Logradouro"
          isDisabled={disabledList.logradouro
             || Boolean(isEdit && formValues?.endereco?.logradouro)
          }
        />

        <InputText
          value={formValues?.endereco?.numero || ''}
          name="numero"
          onChange={handleChangeAddress}
          label="Número"
          isDisabled={disabledList.numero}
        />

        <InputText
          value={formValues?.endereco?.bairro || ''}
          name="bairro"
          onChange={handleChangeAddress}
          label="Bairro"
          isDisabled={disabledList.bairro
             || Boolean(isEdit && formValues?.endereco?.bairro)
          }
        />

        <InputText
          value={formValues?.endereco?.cidade || ''}
          name="cidade"
          onChange={handleChangeAddress}
          label="Cidade"
          isDisabled={disabledList.cidade
             || Boolean(isEdit && formValues?.endereco?.cidade)
          }
        />

        <InputText
          value={formValues?.endereco?.uf || ''}
          name="uf"
          onChange={handleChangeAddress}
          label="UF"
          isDisabled={disabledList.uf
             || Boolean(isEdit && formValues?.endereco?.uf)
          }
        />

        <InputText
          value={formValues?.endereco?.complemento || ''}
          name="complemento"
          onChange={handleChangeAddress}
          label="Complemento"
        />
      </StyledAddressData>
    </FormContainer>
  )
}

ClientForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goToClient: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool
}

ClientForm.defaultProps = {
  isEdit: false,
  goToClient: null
}

export default ClientForm
