import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import FreightForm from '@/views/components/forms/FreightForm'
import FreightList from '../components/forms/FreightList'
import { freightListFilter } from '@/constants/optionsValues'
import Modal from '../fragments/Modal'
import {
  getFreightData,
  postFreightData,
  putFreightData,
  //getOptionsFreightData
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import { 
  StyledBodyContainer,
  StyledBreadCrumb, 
} from './styles'
import { addDays, format } from 'date-fns'
import Loading from '../components/Loading/Loading'
import Pagination from '../fragments/Pagination'
import SelectText from '../fragments/form/SelectText'

const defaultPagination = {
  pagina: 1,
  total_por_pagina: 100
}

const DEFAULT_FORM = {
  previsao_entrega: format(addDays(new Date(), 7), 'yyyy-MM-dd')
}

const Freight = (props) => {
  const { isMobile } = props
  
  const [filterValue, setFilterValue] = useState(-1)
  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [freightsList, setFreightsList] = useState([])
  //const [typeList, setTypeList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(defaultPagination)
  const [showPagination, setShowPagination] = useState(true)
  const [freightToEdit, setFreightToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showFullList, setShowFullList] = useState(null)
  const [showModalRequired, setShowModalRequired] = useState(false)
  const [requiredMessage, setRequiredMessage] = useState('')

  useEffect(async () => {
    //const list = await getOptionsFreightData()
    //setTypeList(list.tipo_veiculos || [])
    handleSearch(defaultPagination.pagina, defaultPagination.total_por_pagina, filterValue)
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }  

  const verifyIfNullOrEmpty = (value) => {
    if(value === null || value === undefined || value === "") {
      return true
    } else {
      return false
    }
  }

  const requiredFields = async (obj) => {
    //var tipo = typeList.find(element => element.value === obj.id_tipoveiculo)

    if(verifyIfNullOrEmpty(obj.id_filial)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de filial!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.id_cliente)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de cliente!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.origem_estado)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de origem estado!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.origem_cidade)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de origem cidade!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.destino_estado)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de destino estado!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.destino_cidade)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de destino cidade!')
      return true
    }
    if(obj.isca) {
      if(verifyIfNullOrEmpty(obj.isca_tecnologia)) {
        setShowModalRequired('warning')
        setRequiredMessage('É necessário preencher o campo de tecnologia da isca!')
        return true
      }
      if(verifyIfNullOrEmpty(obj.isca_numero)) {
        setShowModalRequired('warning')
        setRequiredMessage('É necessário preencher o campo de número da isca!')
        return true
      }
    }
    if(verifyIfNullOrEmpty(obj.previsao_entrega)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de previsão de entrega!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.precificacao)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de precificação!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.preco)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de valor contratado / acordado - motorista!')
      return true
    }
    if(verifyIfNullOrEmpty(obj.preco_mercadoria)) {
      setShowModalRequired('warning')
      setRequiredMessage('É necessário preencher o campo de valor da mercadoria / transportado!')
      return true
    }
    // if(verifyIfNullOrEmpty(obj.id_tipoveiculo)) {
    //   setShowModalRequired('warning')
    //   setRequiredMessage('É necessário preencher o campo de tipo de veículo!')
    //   return true
    // }
    // if(tipo.quantidade_placa >= 1) {
    //   if(verifyIfNullOrEmpty(obj.placa1)) {
    //     setShowModalRequired('warning')
    //     setRequiredMessage('É necessário preencher o campo de placa 1!')
    //     return true    
    //   } 
    // }
    // if(tipo.quantidade_placa >= 2) {
    //   if(verifyIfNullOrEmpty(obj.placa2)) {
    //     setShowModalRequired('warning')
    //     setRequiredMessage('É necessário preencher o campo de placa 2!')
    //     return true    
    //   } 
    // }
    // if(tipo.quantidade_placa >= 3) {
    //   if(verifyIfNullOrEmpty(obj.placa3)) {
    //     setShowModalRequired('warning')
    //     setRequiredMessage('É necessário preencher o campo de placa 3!')
    //     return true    
    //   } 
    // }
    // if(tipo.quantidade_placa >= 4) {
    //   if(verifyIfNullOrEmpty(obj.placa4)) {
    //     setShowModalRequired('warning')
    //     setRequiredMessage('É necessário preencher o campo de placa 4!')
    //     return true    
    //   } 
    // }
    if(obj.alerta) {
      if(verifyIfNullOrEmpty(obj.tempo1)) {
        setShowModalRequired('warning')
        setRequiredMessage('É necessário preencher o campo de tempo 1!')
        return true    
      } 
      if(verifyIfNullOrEmpty(obj.tempo2)) {
        setShowModalRequired('warning')
        setRequiredMessage('É necessário preencher o campo de tempo 2!')
        return true    
      } 
    }
    return false
  }

  const submitForm = async () => {
    setLoading(true)
    const setupValues = {
      ...formValues,
      preco: (formValues.preco != null || formValues.preco != undefined) ? formValues.preco.replace(/\./g,'').replace(',','.') : 0,
      preco_mercadoria: (formValues.preco_mercadoria != null || formValues.preco_mercadoria != undefined) ? formValues.preco_mercadoria.replace(/\./g,'').replace(',','.') : 0,
      isca_tecnologia: formValues?.isca ? formValues?.isca_tecnologia : null,
      isca_numero: formValues?.isca ? formValues?.isca_numero : null,
    }
    
    const required = await requiredFields(setupValues)

    if(required) {
      setLoading(false)
      return
    }

    const req = await postFreightData(setupValues)

    setLoading(false)
    console.log(123);
    if (req.status.active) {
      setShowModal('success')
      setShowType('list-drivers')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }
  }

  const handleEditChange = (form) => {
    setFreightToEdit(form)
  }
  
  const submitEditForm = async () => {
    const setupValues = {
      ...freightToEdit,
      preco: (freightToEdit.preco != null || freightToEdit.preco != undefined) ? freightToEdit.preco.replace(/\./g,'').replace(',','.') : 0,
      preco_mercadoria: (freightToEdit.preco_mercadoria != null || freightToEdit.preco_mercadoria != undefined) ? freightToEdit.preco_mercadoria.replace(/\./g,'').replace(',','.') : 0,
      isca_tecnologia: freightToEdit?.isca ? freightToEdit?.isca_tecnologia : "",
      isca_numero: freightToEdit?.isca ? freightToEdit?.isca_numero : "",
    }
    const req = await putFreightData(setupValues)

    const index = freightsList.findIndex(
      (elem) => elem.id_freighte === freightToEdit.id_frete
    )

    const newForm = [...freightsList]

    newForm[index] = freightToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setFreightsList(newForm)
      setShowFullList(freightToEdit.id_frete)
      handleSearch()
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
    setShowModalRequired(null)
    setRequiredMessage('')
  }

  const onEditFreight = (e) => {
    setFreightToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setFreightToEdit({})
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <FreightForm
              onChange={handleChange}
              formValues={formValues}
            />
            <button
              className='button formButton'
              onClick={submitForm}
            >
              Enviar
            </button>
          </>
        )
      case 'list-drivers':
        return (
          <FreightList
            fullList={freightsList}
            showFullList={showFullList}
            onEditItem={onEditFreight}
            handleSearch={handleSearch}
            isMobile={isMobile}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async (pagina, size, status) => {
    setLoading(true)
    setShowPagination(true)

    let req;

    if(status === undefined) {
      req = await getFreightData(searchBy, defaultPagination.pagina, defaultPagination.total_por_pagina, filterValue)
    } else {
      req = await getFreightData(searchBy, pagina, size, status)
    }

    if(req?.fretes?.length === 0) {
      setFreightsList(req.fretes)
      setShowPagination(false)
      setLoading(false)
      return;
    }

    setPagination({
      pagina: req.paginacao.pagina,
      total_de_paginas: req.paginacao.tamanho_pagina,
      total_de_registros: req.paginacao.itens,
      total_por_pagina: req.paginacao.tamanho
    })

    setLoading(false)
    if (!req) {
      return setShowType(null)
    }

    setShowType('list-drivers')
    setFreightsList(req.fretes)
  }

  const changePagination = (page) => {
    handleSearch(page, pagination.total_por_pagina, filterValue)
  }

  const onChangeFilter = (e) => {
    const { value } = e.target
    
    setFilterValue(value)
    handleSearch(1, pagination.total_por_pagina, value)
  }  
  
  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Frete"
      size='lg'
    >
      <FreightForm
        onChange={handleEditChange}
        formValues={freightToEdit}
        isEdit
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showModalRequired && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg={requiredMessage}
        />
      )}
      {showEditModal && renderEditModal()}
      <Container fullWidth>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Fretes"
              placeholder="Cliente"
              onSubmitSearch={handleSearch}
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            <StyledBreadCrumb>
              <h2>Fretes</h2>
              {(showType === "list-drivers") &&
                <div>
                  <SelectText
                    label="Filtrar"
                    name="filterFreight"
                    onChange={onChangeFilter}
                    options={freightListFilter}
                    value={filterValue}
                  />
                </div>
              }
            </StyledBreadCrumb>
            {renderByType()}
            {(showType === "list-drivers" && showPagination) && 
              <Pagination
                params={pagination}
                requestPage={changePagination}
              />

            }
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

Freight.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default Freight
