import styled from '@emotion/styled'

export const StyledBodyContainer = styled.section`
  button {
    &.formButton {
      float: right;
    }
  }
`

export const StyledH2 = styled.h2`
  display: block;
  width: calc(100% - 20px);
  margin-left: 10px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 50px;
  box-shadow: 3px 3px  0 0 var(--color-third), -3px -3px  0 0 var(--color-third-light);
  color: var(--color-primary);
`
