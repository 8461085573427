import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import {
  formatterNumberOnly,
} from '@/utils/formatter'
import {
  FormContainer,
  StyledBasicData,
} from './styles'

const TypeLoadForm = (props) => {
  const {
    onChange,
    formValues,
  } = props

  
  useEffect(() => {
  }, [])


  const handleChange = (e, ml) => {
    const { value, name} = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value
    })
  }
  const handleChangeToggle = (name, bool) => {
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  return (
    <>
      <FormContainer>
        <StyledBasicData>
          <h3>Dados Básicos</h3>
          <InputText
            value={formValues.nome || ''}
            name="nome"
            onChange={handleChange}
            label="Nome"
          />
          <ToggleButton
            label="Status Ativo?"
            value={Number(formValues.status) === 1 || false}
            name="status"
            onClick={handleChangeToggle}
          />
        </StyledBasicData>
      </FormContainer>
    </>
  )
}

TypeLoadForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goToTypeLoad: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool
}

TypeLoadForm.defaultProps = {
  isEdit: false,
  goToTypeLoad: null
}

export default TypeLoadForm
