import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FREIGHT } from '@/constants/parameters'
import { NavLink } from 'react-router-dom'
import { MENU_DEFAULT } from '@/constants/optionsValues'
import {
  StyledMenuContainer,
} from './styles'

const Menu = (props) => {
  const { onMenuClick, isMobile } = props

  const [showCompleteMenu, setShowCompleteMenu] = useState(false)

  const onClick = () => {
    setShowCompleteMenu(false)
    onMenuClick()
  }

  const renderMenuItens = (item) => {
    return (
      <li key={item.id}>
        <NavLink
          to={item.id === FREIGHT ? '/' : item.id}
          onClick={onClick}
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          <span>
            <i className={item.icon} />
            {item.label}
          </span>
        </NavLink>
      </li>
    )
  }

  return (
    <StyledMenuContainer>
      <li
        className={showCompleteMenu ? 'sub active' : 'sub'}
      >
        {!isMobile && (
          <a
            onClick={() => setShowCompleteMenu(!showCompleteMenu)}
          >
            <span>
              <i className="fas fa-bars" />
              Menu
            </span>
          </a>
        )}
        {showCompleteMenu && (
          <div className="subMenu">
            <ul>
              {MENU_DEFAULT.map((item) => !item.showTop && renderMenuItens(item))}
            </ul>
          </div>
        )}
      </li>
      {MENU_DEFAULT.map((item) => {
        if (isMobile) {
          return renderMenuItens(item)
        }

        return item.showTop && renderMenuItens(item)
      })}
    </StyledMenuContainer>
  )
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired
}

Menu.defaultProps = {
  onMenuClick: null
}

export default Menu
