import React from 'react'
import PropTypes from 'prop-types'
//import ToggleButton from '@/views/fragments/form/ToggleButton'
import Accordeon from '@/views/components/Accordeon'
import { UserContainer } from './styles'
//import { formatterCPF } from '@/utils/formatter'

const UserList = (props) => {
  const {
    onShowItem,
    onEditItem,
    fullList,
    showFullList
  } = props

  const handleShowClient = (params) => {
    onShowItem(params.USR_CODIGO)
  }

  const renderList = (user, i) => (
    <Accordeon
      key={i}
      params={user}
      showItem={Boolean(showFullList === user.USR_CODIGO)}
      onShowItem={handleShowClient}
      onEditItem={onEditItem}
      title="Login"
      titleValue={user.login}
      subtitle="nome"
      subtitleValue={user.nome}
    >
      <>
        <div>
          <span>Nome</span>
          <p>{user.nome || '-'}</p>
        </div>
        <div>
          <span>Login</span>
          <p>{user.login || '-'}</p>
        </div>
        <div>
          <span>Email</span>
          <p>{user.email || '-'}</p>
        </div>
        <div>
          <span>Telefone</span>
          <p>{user.telefone || '-'}</p>
        </div>
      </>
    </Accordeon>
  )

  return (
    <UserContainer>
      {fullList.map((user, index) => renderList(user, index))}
    </UserContainer>
  )
}

UserList.propTypes = {
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFullList: PropTypes.number
}

UserList.defaultProps = {
  showFullList: null
}

export default UserList
