import styled from '@emotion/styled'

export const CustomModal = styled.div`
  h4 {
    margin-bottom: 10px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 0 !important;
  }

  .buttons-modal {
    display: flex;
    gap: 30px; 

    button {
      width: 100%;
    }
  }

  input {
    padding: 0 10px;
  }
`
