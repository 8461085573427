import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import Accordeon from '@/views/components/Accordeon'
import { ClientsContainer } from './styles'
import { formatterCPForCNPJ } from '@/utils/formatter'

const ClientList = (props) => {
  const {
    onShowItem,
    onEditItem,
    fullList,
    showFullList
  } = props

  const handleShowClient = (params) => {
    onShowItem(params.id_cliente)
  }

  const renderList = (client, i) => (
    <Accordeon
      key={i}
      params={client}
      showItem={Boolean(showFullList === client.id_cliente)}
      onShowItem={handleShowClient}
      onEditItem={onEditItem}
      title={client.tipo === 2 ? 'CNPJ' : 'CPF'}
      titleValue={formatterCPForCNPJ(client.doc_number)}
      subtitle={client.tipo === 1 ? 'Nome' : 'Nome Fantasia'}
      subtitleValue={client.tipo === 1 ? client.nome : client.nome_fantasia}
    >
      <>
        {client.tipo === 1 ? (
        <>
          <div>
            <span>Nome</span>
            <p>{client.nome || '-'}</p>
          </div>
          <div>
            <span>Apelido</span>
            <p>{client.apelido || '-'}</p>
          </div>
          <div>
            <span>RG</span>
            <p>{client.rg || '-'}</p>
          </div>
        </>
        ) : (
        <>
          <div>
            <span>Incrição Estadual</span>
            <p>{client.inscricao_estadual || '-'}</p>
          </div>
          <div>
            <span>Nome Fantasia</span>
            <p>{client.nome_fantasia || '-'}</p>
          </div>
          <div>
            <span>Razão Social</span>
            <p>{client.razao_social || '-'}</p>
          </div>
        </>
        )}
        <div>
          <span>Email</span>
          <p>{client.email || '-'}</p>
        </div>
        <div>
          <span>Telefone</span>
          <p>{client.telefone || '-'}</p>
        </div>
        <div className='address'>
          <h4>Endereço</h4>
        </div>
        <div>
          <span>CEP</span>
          <p>{client.endereco.cep || '-'}</p>
        </div>
        <div>
          <span>Logradouro</span>
          <p>{client.endereco.logradouro || '-'}</p>
        </div>
        <div>
          <span>numero</span>
          <p>{client.endereco.numero || '-'}</p>
        </div>
        <div>
          <span>bairro</span>
          <p>{client.endereco.bairro || '-'}</p>
        </div>
        <div>
          <span>cidade</span>
          <p>{client.endereco.cidade || '-'}</p>
        </div>
        <div>
          <span>estado</span>
          <p>{client.endereco.uf || '-'}</p>
        </div>
        <div>
          <span>complemento</span>
          <p>{client.endereco.complemento || '-'}</p>
        </div>
        <div>
          <span>Status Ativo</span>
          <ToggleButton
            value={client.status === 1 || false}
            className="styledToggle"
            readOnly
          />
        </div>
      </>
    </Accordeon>
  )

  return (
    <ClientsContainer>
      {fullList.map((client, index) => renderList(client, index))}
    </ClientsContainer>
  )
}

ClientList.propTypes = {
  onShowItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  fullList: PropTypes.arrayOf(PropTypes.object).isRequired,
  showFullList: PropTypes.number
}

ClientList.defaultProps = {
  showFullList: null
}

export default ClientList
