import styled from '@emotion/styled'

export const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 10px;
  }
`
