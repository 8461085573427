import React, { useCallback, useState, useEffect } from 'react'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import ParentData from '../components/forms/ParentData'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import { getMatrizData, postMatrizData, putMatrizData } from '@/constants/requests'
import ParentList from '../components/forms/ParentList'
import Modal from '../fragments/Modal'
import FormEdit from '../components/forms/ParentData/components/FormEdit'
import Loading from '../components/Loading/Loading'
import { formatterNumberOnly } from '@/utils/formatter'
import { StyledBodyContainer, StyledH2 } from './styles'

const DEFAULT_FORM = {
  matriz: 0
}

const SubsidiaryAndParent = () => {

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [showModal, setShowModal] = useState(false)
  const [searchBy, setSearchBy] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showSiglaModal, setShowSiglaModal] = useState(false)
  const [subsidiaryData, setSubsidiaryData] = useState(null)
  const [showItem, setShowItem] = useState(null)
  const [parentList, setParentList] = useState([])
  const [parentToEdit, setParentToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const getSubusidiary = useCallback(async() => {
    const data = await getMatrizData()
    filterSubsidiary(data.matrizes)
  }, [])

  useEffect(() => {
    getSubusidiary()
  }, [])

  const handleSearch = async () => {
    setLoading(true)
    const data = await getMatrizData(searchBy)

    const index = data.matrizes.findIndex((el) => el.matriz === 1)
    if(index >= 0) {
      data.matrizes.splice(index, 1)
    }

    setShowType('list-clients')
    setParentList(data.matrizes)
    setLoading(false)
  }

  const onCloseModal = () => {
    setShowModal(null)
  }

  const handleChangeForm = (form) => {
    setFormValues(form)
  }

  const filterSubsidiary = (data) => {
    const item = data.find((el) => el.matriz === 1)
    setSubsidiaryData(item)
  }

  const handleEditChange = (form) => {
    setParentToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues,
      doc_number: formatterNumberOnly(formValues.doc_number),
      telefone: formatterNumberOnly(formValues.telefone) || null,
      inscricao_estadual: formatterNumberOnly(formValues.inscricao_estadual) || null,
      endereco: {
        ...formValues.endereco,
        cep: formatterNumberOnly(formValues.endereco?.cep),
      }
    }
    return form
  }

  const submitForm = async () => {
    const form = setupForm()

    if(form.sigla == undefined || form.sigla == null || form.sigla == "") {
      setShowSiglaModal(true)
      document.getElementById("sigla").focus()
      return
    }
    setLoading(true)

    const req = await postMatrizData(form)

    if (req.status.active) {
      setShowModal('sucess')
      setShowType('list-clients')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }

    setLoading(false)
  }

  const submitEditForm = async () => {
    const req = await putMatrizData(parentToEdit)
    if (req.status.active) {
      setShowModal('sucess')
      setShowEditModal(false)
      handleSearch()
    }
  }

  const handleChange = (e) => {
    setSubsidiaryData(e)
  }

  const onShowParent = (e) => {
    setShowItem(e === showItem ? null : e)
  }

  const onEditParent = (e) => {
    setParentToEdit(e)
    setShowEditModal(true)
  }

  const handleSubmit = async () => {
    setShowSuccessModal(true)
  }


  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <StyledH2>Cadastro de Filiais</StyledH2>
            <FormEdit
              subsidiary={formValues}
              goToParent={onEditParent}
              onChange={handleChangeForm}
            />
            <button
              className='button formButton'
              onClick={submitForm}
            >
              Enviar
            </button>
          </>
        )
      case 'list-clients':
        return (
          <>
            <StyledH2>Lista de Filiais</StyledH2>
            <ParentList
              onShowParent={onShowParent}
              onEditParent={onEditParent}
              fullList={parentList}
              showItem={showItem}
            />
          </>
        )
      default:
        return (
          <>
            <StyledH2>Matriz</StyledH2>
            <ParentData
              subsidiary={subsidiaryData}
              handleEditSubsidiary={handleChange}
              submitFormSuccess={handleSubmit}
            />
          </>
        )
    }
  }

  const onParentDataClick = async () => {
    getSubusidiary()
    setShowType(null)
  }

  const renderSuccessModal = () => (
    <DefaultModalAlert
      msg="Cadastrado com sucesso!"
      closeModal={() => setShowSuccessModal(false)}
    />
  )

  const renderSiglaModal = () => (
    <DefaultModalAlert
      msg="A sigla é necessária no cadastro!"
      closeModal={() => setShowSiglaModal(false)}
    />
  )

  const handleCloseModal = () => {
    setShowEditModal(false)
    setParentToEdit({})
  }

  const renderEditModal = () => (
    <Modal
      closeModal={handleCloseModal}
      size="md"
    >
      <FormEdit
        subsidiary={parentToEdit}
        goToParent={onEditParent}
        onChange={handleEditChange}
        isEdit
      />
      <button
        className='button formButton'
        onClick={submitEditForm}
      >
        Enviar
      </button>
    </Modal>
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showSuccessModal && renderSuccessModal()}
      {showSiglaModal && renderSiglaModal()}
      {showEditModal && renderEditModal()}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="filiais"
              onChange={handleChangeFilter}
              label="Buscar Filiais"
              onSubmitSearch={handleSearch}
              placeholder="CNPJ ou Razão Social"
            />
            <button className='button full-width' onClick={onParentDataClick}>
              Matriz
            </button>
            <button className='button full-width' onClick={() => setShowType('new-form')}>
              Cadastrar Filiais
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

export default SubsidiaryAndParent
