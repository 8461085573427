import styled from '@emotion/styled'

export const StyledTemplate = styled.div`
  width: 100%;

  @media (min-width: 780px) {
    padding: 20px 0;
    display: flex;
  }
`
