import styled from '@emotion/styled'

export const Field = styled.label`
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
  position: relative;
  vertical-align: top;

  & > span {
    color: var(--color-primary);
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;

    i {
      font-size: 8px;
      vertical-align: top;
      color: red;
      margin-left: 3px;
    }
  }

  input, select, textarea {
    margin-top: 1px;
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;

    &.alert {
      box-shadow: 3px 3px 0 0 var(--color-alert);
    }

    &.success {
      border-color: var(--color-success);
    }
  }

  textarea {
    height: 100px;
    width: 100%;
    display: block;
  }
`

export const CustomButtonToggle = styled.div`
  font-size: 30px;
  padding-top: 18px;

  i {
    color: var(--color-gray-dark);
    vertical-align: middle;

    &.toggleOn {
      color: var(--color-primary);
    }
  }

  span {
    font-size: 15px;
    margin-left: 10px;
    vertical-align: middle;
    color: var(--color-gray-dark);
    font-weight: 600;
  }
`

export const CustomRadioButton = styled.div`
  font-size: 20px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 20px;

  i {
    color: var(--color-gray-dark);
    vertical-align: middle;
    margin-left: 10px;

    &.toggleOn {
      color: var(--color-primary);
    }
  }

  span {
    font-size: 15px;
    color: var(--color-primary);
    font-weight: 600;
    line-height: 25px;
  }
`

export const LabelInputRadio = styled.span`
  display: block;
`

export const CustomLoading = styled.i`
  position: absolute;
  top: 22px;
  z-index: 10;
  left: calc(50% - 15px);
`

export const CustomSearchButton = styled.button`
  position: absolute;
  bottom: 2px;
  z-index: 10;
  font-size: 20px;
  right: 2px;
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 4px;
  background-color: var(--color-third);
  color: var(--color-third-light);
`

export const StyledHint = styled.span`
  display: block;
  color: var(--color-alert) !important;
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;

  &.success {
    color: var(--color-success) !important;
  }
`

export const StyledInputSearch = styled.input`
  padding-right: 50px !important;
`

export const StyledMultiple = styled.div`
  position: relative;

  .sected-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px;
    padding: 5px;
    margin-bottom: 5px;

    span {
      display: block;
      padding: 5px 10px;
      font-weight: 600;
      font-size: 14px;
      color: var(--color-light);
      border-radius: 10px;
      background-color: var(--color-third);

      button {
        background-color: transparent;
        border: none;
        margin-left: 5px;
        color: var(--color-secondary);
      }
    }
  }

  .input-multiple {
    position: relative;

    button {
      width: 44px;
      height: 44px;
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 0;
      color: var(--color-primary);
    }
  }

  ul {
    border: 2px solid var(--color-third);
    padding: 10px 15px;
    border-radius: 5px;
    position: absolute;
    max-height: 280px;
    overflow: auto;
    width: 100%;
    z-index: 10;
    background-color: var(--color-light);
    top: 41px;

    li {
      display: block;
      line-height: 20px;
      padding: 5px;

      &:hover {
        background-color: var(--color-third);
        color: var(--color-light);
      }
    }
  }
`
