import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputText from '@/views/fragments/form/InputText'
import ToggleButton from '@/views/fragments/form/ToggleButton'
import {
  formatterMoney,
  formatterNumberOnly,
} from '@/utils/formatter'
import {
  FormContainer,
  StyledBasicData,
} from './styles'
import SelectText from '@/views/fragments/form/SelectText'
import {
  getOptionsFreightData,
  getCityData
} from '@/constants/requests'

const FreightForm = (props) => {
  const {
    onChange,
    formValues,
    isEdit,
  } = props
  
  const [branchesList, setBranchesList] = useState([])
  const [statesList, setStatesList] = useState([])
  const [clientsList, setClientsList] = useState([])
  const [pricingList, setPricingList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [cityOrigemList, setCityOrigem] = useState([])
  const [cityDestinoList, setCityDestino] = useState([])
  const [loadList, setLoad] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isIsca, setIsIsca] = useState(false)
  const [isAlert, setIsAlert] = useState(false)
  const [formPlacas, setFormPlacas] = useState([]);

  const getFullList = useCallback(async () => {
    setIsLoading(true)
    const list = await getOptionsFreightData()
    setBranchesList(list.filiais || [])
    setStatesList(list.estados || [])
    setClientsList(list.clientes || [])
    setPricingList(list.precificacao || [])
    setTypeList(list.tipo_veiculos || [])
    setLoad(list.carga || [])
    setIsLoading(false)
  });
  
  useEffect(async() => {
    let preco, preco_mercadoria;

    if(formValues?.preco !== undefined && formValues?.preco !== null) {
      preco = formatterMoney(formValues?.preco)
    }

    if(formValues?.preco_mercadoria !== undefined && formValues?.preco_mercadoria !== null) {
      preco_mercadoria = formatterMoney(formValues?.preco_mercadoria)
    }

    if(formValues?.destino_estado !== undefined && formValues?.destino_estado !== null) {
      const list = await handleCity(formValues?.destino_estado)
      setCityDestino(list.cidades || [])
    }

    if(formValues?.origem_estado !== undefined && formValues?.origem_estado !== null) {
      const list = await handleCity(formValues?.origem_estado)
      setCityOrigem(list.cidades || [])
    } 
    
    getFullList()

    if(formValues?.id_tipoveiculo !== null && formValues?.id_tipoveiculo !== undefined) {
      const values = [];

      for (let i = 0; i < formValues?.quantidade_placa; i++) {
        var placa = {
          label: "Placa " + i,
          type: "text",
          value: "a",
        }
        values.push(placa)
        
      }
      setFormPlacas(values)
    } 
    
    setIsIsca(formValues.isca || 0)
    setIsAlert(formValues.alerta || 0)

    onChange({
      ...formValues,
      isca: formValues?.isca || 0,
      alerta: formValues?.alerta || 0,
      tempo1: '04:00',
      tempo2: '05:00',
      preco: preco,
      preco_mercadoria: preco_mercadoria
    });

  }, [])

  const handleChange = (e, ml) => {
    const { value, name} = e.target

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: ((name === 'preco' || name === 'preco_mercadoria') ? formatterMoney(value) : (name === 'peso') ? formatterNumberOnly(value) : value)
    })

    if(name === 'id_tipoveiculo') {
      var tipo = typeList.find(element => element.value === value);
      const values = [];

      if(value !== "-1") {

        for (let i = 0; i < tipo.quantidade_placa; i++) {
          var placa = {
            label: "Placa " + i,
            type: "text",
            value: "",
          }
          values.push(placa)
          
        }
        
        setFormPlacas(values)

      } else {
        setFormPlacas([])
      }
    } 
  }

  const handleCity = async (value) => {
    return await getCityData(value)
  }

  const handleChangeOrigem = async (e, ml) => {
    const { value, name} = e.target
    setIsLoading(true)

    const list = await handleCity(value)
    setCityOrigem(list.cidades || [])

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value
    })
    setIsLoading(false)
  }
  
  const handleChangeDestino = async (e, ml) => {
    const { value, name} = e.target
    setIsLoading(true)

    const list = await handleCity(value)
    setCityDestino(list.cidades || [])

    if (formatterNumberOnly(value).length > ml) return

    onChange({
      ...formValues,
      [name]: value
    })
    setIsLoading(false)
  }

  const handleChangeToggle = (name, bool) => {
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  const handleChangeToggleIsca = (name, bool) => {
    setIsIsca(bool)
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  const handleChangeToggleAlert = (name, bool) => {
    setIsAlert(bool)
    onChange({
      ...formValues,
      [name]: bool ? 1 : 0
    })
  }

  return (
    <>
     <FormContainer key={getFullList}>
        <StyledBasicData>
          <h3>Cadastro de Frete</h3>
          <SelectText
            label="Filial"
            name="id_filial"
            isRequired
            onChange={handleChange}
            options={branchesList}
            value={formValues.id_filial || ''}
          />
          <SelectText
            label="Cliente"
            name="id_cliente"
            isRequired
            onChange={handleChange}
            options={clientsList}
            value={formValues.id_cliente || ''}
          />
        </StyledBasicData>
        <StyledBasicData>
          <h3>Dados de Origem</h3>
          <SelectText
            label="Estado"
            name="origem_estado"
            onChange={handleChangeOrigem}
            isRequired
            options={statesList}
            isLoading={isLoading}
            value={formValues.origem_estado || ''}
          />
          <SelectText
            label="Cidade"
            name="origem_cidade"
            onChange={handleChange}
            isRequired
            options={cityOrigemList}
            isLoading={isLoading}
            value={formValues.origem_cidade || ''}
          />
        </StyledBasicData>
        <StyledBasicData>
          <h3>Dados de Destino</h3>
          <SelectText
            label="Estado"
            name="destino_estado"
            onChange={handleChangeDestino}
            isRequired
            options={statesList}
            isLoading={isLoading}
            value={formValues.destino_estado || ''}
          />
          <SelectText
            label="Cidade"
            name="destino_cidade"
            onChange={handleChange}
            isRequired
            options={cityDestinoList}
            isLoading={isLoading}
            value={formValues.destino_cidade || ''}
          />
          
          <h3 style={{marginTop: "30px"}}>Isca</h3>
          <div>
            <ToggleButton
              value={Number(formValues.isca) === 1 || false}
              name="isca"
              label="Isca"
              className="styledToggle"
              onClick={handleChangeToggleIsca}
            />
          </div>
          {isIsca ? (
            <InputText
              value={formValues.isca_tecnologia || ''}
              name="isca_tecnologia"
              isRequired
              onChange={handleChange}
              label="Tecnologia"
            />
          ) : null}
          {isIsca ? (
            <InputText
              value={formValues.isca_numero || ''}
              name="isca_numero"
              isRequired
              type="number"
              onChange={handleChange}
              label="Número"
            />
          ) : null}
        </StyledBasicData>
        <StyledBasicData>
          <h3>Carga</h3>
          <InputText
            value={formValues.previsao_entrega || ''}
            name="previsao_entrega"
            isRequired
            type="datetime-local"
            onChange={handleChange}
            label="Previsão de Entrega"
          />
          <InputText
            value={formValues.peso || ''}
            name="peso"
            onChange={handleChange}
            label="Peso em KG"
          />
          <InputText
            value={formValues.contrato || ''}
            name="contrato"
            onChange={handleChange}
            label="Contrato"
          />
          <SelectText
            label="Precificação"
            name="precificacao"
            isRequired
            onChange={handleChange}
            options={pricingList}
            value={formValues.precificacao || ''}
          />
          <SelectText
            label="Tipo de Carga"
            name="tipo_carga"
            onChange={handleChange}
            options={loadList}
            value={formValues.tipo_carga || ''}
          />
          <InputText
            value={formValues.preco || ''}
            isRequired
            name="preco"
            onChange={handleChange}
            label="Valor Contratado / Acordado - Motorista"
          />
          <InputText
            value={formValues.preco_mercadoria || ''}
            isRequired
            name="preco_mercadoria"
            onChange={handleChange}
            label="Valor da Mercadoria / Transportado"
          />
          <div>
            <ToggleButton
              value={Number(formValues.pedagio) === 1 || false}
              name="pedagio"
              label="Pedágio Incluso?"
              className="styledToggle"
              onClick={handleChangeToggle}
            />
          </div>
          <InputText
            value={formValues.observacoes || ''}
            name="observacoes"
            onChange={handleChange}
            label="Observação"
          />
        </StyledBasicData>
        <StyledBasicData>
          <h3>Placas Veículos</h3>
          <SelectText
            label="Tipo Veículo"
            name="id_tipoveiculo"
            isDisabled={isEdit}
            onChange={handleChange}
            options={typeList}
            value={formValues.id_tipoveiculo || ''}
          />
          {formPlacas.map((obj, index) => (
            <InputText
              label={"Placa " + (index + 1)}
              name={"placa"+(index+1)}
              isDisabled={isEdit}
              size={7}
              value={formValues["placa"+(index+1)] || ''}
              key={index}
              objValue={obj}
              onChange={handleChange}
              index={index}
            />
          ))}
        </StyledBasicData>
        <StyledBasicData>
          <h3>Alerta</h3>
          <div>
            <ToggleButton
              value={Number(formValues.alerta) === 1 || false}
              name="alerta"
              label="Alterar tempo padrão de alerta?"
              className="styledToggle"
              onClick={handleChangeToggleAlert}
            />
          </div>
          {isAlert ? (
            <InputText
              label={"Tempo 1"}
              name={"tempo1"}
              type='time'
              value={formValues?.tempo1 || "04:00"}
              onChange={handleChange}
              testeDeValue
            />
          ) : null}
          {isAlert ? (
            <InputText
              label={"Tempo 2"}
              name={"tempo2"}
              type='time'
              value={formValues?.tempo2 || "05:00"}
              min={formValues?.tempo1 || "05:00"}
              onChange={handleChange}
            />
          ) : null}
          
          {isAlert ? (
            <div>
              <p>Entre 0 e {formValues?.tempo1 || "04:00"} - Nada</p>
              <p>Entre {formValues?.tempo1 || "04:00"} e {formValues?.tempo2 || "05:00"} - Amarelo</p>
              <p>De {formValues?.tempo2 || "05:00"} acima - Vermelho</p>
            </div>
          ) : null}
        </StyledBasicData>
      </FormContainer>
    </>
  )
}

FreightForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  goToDriver: PropTypes.func,
  formValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEdit: PropTypes.bool
}

FreightForm.defaultProps = {
  isEdit: false,
  goToDriver: null
}

export default FreightForm
