import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsideDefault from '@/views/components/AsideDefault'
import Container from '@/views/components/Container'
import TemplateContainer from '@/views/components/TemplateContainer'
import InputSearch from '@/views/fragments/form/InputSearch'
import LoadEmptyPage from '../components/LoadEmptyPage'
import TypeLoadForm from '@/views/components/forms/TypeLoadForm'
import TypeLoadList from '../components/forms/TypeLoadList'
import {
  getTypeLoadData,
  postTypeLoadData,
  putTypeLoadData
} from '@/constants/requests'
import DefaultModalAlert from '../fragments/modalTypes/DefaultModalAlert'
import Modal from '../fragments/Modal'
import Loading from '../components/Loading/Loading'
import { StyledBodyContainer } from './styles'

const DEFAULT_FORM = {
  tipo: 1,
  active: 1
}

const TypeLoad = (props) => {
  const { isMobile } = props

  const [showType, setShowType] = useState(null)
  const [formValues, setFormValues] = useState(DEFAULT_FORM)
  const [searchBy, setSearchBy] = useState('')
  const [typesList, setTypesList] = useState([])
  const [showFullList, setShowFullList] = useState(null)
  const [loadToEdit, setLoadToEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const getFullList = useCallback(async () => {
    const list = await getTypeLoadData()
    setTypesList(list.tipos_cargas || [])
  }, [])

  useEffect(() => {
    getFullList()
  }, [])

  const handleChange = (form) => {
    setFormValues(form)
  }

  const handleEditChange = (form) => {
    setLoadToEdit(form)
  }

  const setupForm = () => {
    const form = {
      ...formValues
    }
    return form
  }

  const submitForm = async () => {
    const form = setupForm()

    setLoading(true)

    const req = await postTypeLoadData(form)
    if (req.status.active) {
      setLoading(false)
      setShowModal('sucess')
      setShowType('list-type-load')
      setFormValues(DEFAULT_FORM)
      handleSearch()
    }
  }

  const submitEditForm = async () => {
    const req = await putTypeLoadData(loadToEdit)

    const index = typesList.findIndex(
      (elem) => elem.id_tipocarga === loadToEdit.id_tipocarga
    )

    const newForm = [...typesList]

    newForm[index] = loadToEdit

    if (req.status.active) {
      setShowModal('sucess')
      setFormValues(DEFAULT_FORM)
      onCloseEditModal()
      setTypesList(newForm)
      setShowFullList(loadToEdit.id_tipocarga)
      handleSearch()
    }
  }

  const onCloseModal = () => {
    setShowModal(null)
  }

  const onShowLoad = (e) => {
    setShowFullList(e === showFullList ? null : e)
  }

  const onEditLoad = (e) => {
    setLoadToEdit(e)
    setShowEditModal(true)
  }

  const onCloseEditModal = () => {
    setShowEditModal(false)
    setLoadToEdit({})
  }

  const onShowConfirmModal = (load) => {
    setLoadToEdit(load)
    setShowEditModal(true)
    setFormValues(DEFAULT_FORM)
  }

  const renderByType = () => {
    switch (showType) {
      case 'new-form':
        return (
          <>
            <TypeLoadForm
              onChange={handleChange}
              formValues={formValues}
              goToDriver={onShowConfirmModal}
            />
            <button className='button formButton' onClick={submitForm}>Enviar</button>
          </>
        )
      case 'list-type-load':
        return (
          <TypeLoadList
            onShowItem={onShowLoad}
            onEditItem={onEditLoad}
            fullList={typesList}
            showFullList={showFullList}
          />
        )
      default:
        return (
          <LoadEmptyPage isMobile={isMobile} />
        )
    }
  }

  const handleRegister = () => {
    setShowType('new-form')
    setFormValues(DEFAULT_FORM)
  }

  const renderEditModal = () => (
    <Modal
      closeModal={onCloseEditModal}
      title="Editar Tipo do Carga"
      size='lg'
    >
      <TypeLoadForm
        onChange={handleEditChange}
        formValues={loadToEdit}
        isEdit
      />
      <button className='button formButton' onClick={submitEditForm}>Enviar</button>
    </Modal>
  )

  const handleChangeFilter = (e) => {
    setSearchBy(e.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const req = await getTypeLoadData(searchBy)
    setLoading(false)

    if (!req) {
      return setShowType(null)
    }

    setShowType('list-type-load')
    setShowFullList(req.tipos_cargas.id_tipocarga)
    setTypesList(req.tipos_cargas)
  }

  return (
    <section className='section'>
      {loading && (
        <Loading msg="Aguarde um instante..." />
      )}
      {showModal && (
        <DefaultModalAlert
          closeModal={onCloseModal}
          msg="Cadastrado com sucesso!"
        />
      )}
      {showEditModal && renderEditModal()}
      <Container>
        <TemplateContainer>
          <AsideDefault>
            <InputSearch
              value={searchBy}
              name="buscar"
              onChange={handleChangeFilter}
              label="Buscar Tipos de Cargas"
              placeholder="Nome"
              onSubmitSearch={handleSearch}
            />
            <button className='button full-width' onClick={handleRegister}>
              Cadastrar Novo
            </button>
          </AsideDefault>
          <StyledBodyContainer>
            {renderByType()}
          </StyledBodyContainer>
        </TemplateContainer>
      </Container>
    </section>
  )
}

TypeLoad.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default TypeLoad
