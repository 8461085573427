import styled from '@emotion/styled'

export const FormContainer = styled.div`
  width: 100%;
  padding: 20px 10px;

  h3 {
    text-align: center;
    color: var(--color-third);
    margin-bottom: 25px;
    border-bottom: 1px solid;
    border-top: 1px solid;
    padding: 13px 0 10px;
  }

  @media (min-width: 1024px) {
    display: flex;
    gap: 30px;
  }
`

export const StyledBasicData = styled.div`
  width: 100%;
`

export const StyledAddressData = styled.div`
  width: 100%;
`
